/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import { CheckboxProps, Form, FormItemProps, InputNumberProps, InputProps as AntInputProps } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import { CheckBox } from './CheckBox';
import { Input } from './Input';
import { InputNumber } from './InputNumber';
import { PasswordInput } from './PasswordInput';
import { TextArea } from './TextArea';

type LabelInputProps =
  | {
      inputProps: AntInputProps;
      checkBoxProps?: never;
      textAreaProps?: never;
      inputType?: never;
      inputNumberProps?: never;
    }
  | {
      inputProps?: never;
      checkBoxProps: CheckboxProps;
      textAreaProps?: never;
      inputType: 'checkbox';
      inputNumberProps?: never;
    }
  | {
      inputProps?: never;
      textAreaProps: TextAreaProps;
      checkBoxProps?: never;
      inputType: 'textarea';
      inputNumberProps?: never;
    }
  | {
      inputProps: AntInputProps;
      checkBoxProps?: never;
      textAreaProps?: never;
      inputType: 'password';
      inputNumberProps?: never;
    }
  | {
      inputNumberProps: InputNumberProps;
      checkBoxProps?: never;
      textAreaProps?: never;
      inputType: 'number';
      inputProps?: never;
    };

const LabelInput: FC<LabelInputProps & FormItemProps> = ({
  inputProps,
  inputType,
  textAreaProps,
  checkBoxProps,
  inputNumberProps,
  ...props
}) => {
  if (inputType === 'checkbox') {
    return (
      <Form.Item {...props}>
        <CheckBox {...checkBoxProps} />
      </Form.Item>
    );
  }
  if (inputType === 'textarea') {
    return (
      <Form.Item {...props}>
        <TextArea {...textAreaProps} />
      </Form.Item>
    );
  }
  if (inputType === 'password') {
    return (
      <Form.Item {...props}>
        <PasswordInput {...inputProps} />
      </Form.Item>
    );
  }

  if (inputType === 'number') {
    return (
      <Form.Item {...props}>
        <InputNumber {...inputNumberProps} />
      </Form.Item>
    );
  }
  return (
    <Form.Item {...props}>
      <Input {...inputProps} />
    </Form.Item>
  );
};

export { LabelInput };
