import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';

const UnAuthorizedRoutes = () => {
  const { loggedIn } = useAuth();

  return loggedIn ? <Navigate to="/" replace /> : <Outlet />;
};

export default UnAuthorizedRoutes;
