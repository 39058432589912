import { notification } from 'antd';
import { errorMessages, successMessages } from '../en';
import { NotifierTitle } from '../enums';
import { generateErrorMessage } from '../utilFunctions';

const success = (title: NotifierTitle, description?: string): void => {
  notification.success({
    message: successMessages[title].message,
    description: description || successMessages[title].description,
    placement: 'topRight',
  });
};

const error = (title: NotifierTitle, description?: string): void => {
  notification.error({
    ...generateErrorMessage(title, description),
    placement: 'topRight',
  });
};

const generic = (): void => {
  notification.error({
    message: errorMessages.generic.message,
    description: errorMessages.generic.description,
    placement: 'topRight',
  });
};

const notifier = {
  error,
  generic,
  success,
};

export default notifier;
