import React from 'react';
import { Button } from 'components/ui/Button/Button';
import { Card } from 'components/ui/Card/Card';
import { LabelInput } from 'components/ui/Form';
import { useStepperContext } from '../context/StepperContext';

const Step4 = () => {
  const { handleNext, handlePrev } = useStepperContext();

  return (
    <>
      <p className="stepFormTitle ">{`4. What's your community's name?`}</p>
      <Card className="w-3/4 m-auto h-[522px]">
        <div className="flex flex-col h-full w-full">
          <div className="flex justify-start">
            <p onClick={handlePrev} className="cursor-pointer  text-lg" aria-hidden>
              <span className="text-primary">{`<-- `}</span>
              Back: Select Goals
            </p>
          </div>
          <div className="h-full w-full flex flex-col items-start justify-center">
            <LabelInput
              name="name"
              rules={[{ required: true, message: 'Please enter your community name' }]}
              className="!w-full"
              inputProps={{
                placeholder: 'Enter the name of a community',
                className: '!w-full !border-transparent !shadow-none !border-b-grayScale rounded-none mb-0',
              }}
            />
          </div>
          <div className="flex justify-between items-center">
            <p className="text-secondaryDark">Community name must be unique</p>
            <Button type="primary" onClick={handleNext} className="primaryBtn">
              Continue
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default Step4;
