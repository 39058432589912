import { FC, useEffect } from 'react';
import AOS from 'aos';
import { FAQCard } from 'components/ui/Card';
import { Spacer } from 'components/ui/Spacer';
import { faqsDatas } from 'features/faq/faq';
import styles from './styles.module.scss';
import 'aos/dist/aos.css';

export const FAQs: FC = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className={styles.faqs}>
      <div className={styles.faqs__wrap}>
        <div className={styles.faqs__text__header} data-aos="fade-up" data-aos-duration="1000">
          <h1 className={styles.faqs__text__top}>FAQs</h1>
          <h1 className={styles.faqs__text__bottom}>Have a question? Here are the most frequently asked</h1>
          <h1 className={styles.faqs__text__bottom}>questions from our various communities.</h1>
        </div>
        <Spacer height="20px" />
        {faqsDatas.slice(0, 4).map((faqData, index, array) => {
          return (
            <>
              <FAQCard title={faqData.title} desc={faqData.desc} faqTypes={faqData.faqTypes} />
              {index !== array.length - 1 && <Spacer height="20px" />}
            </>
          );
        })}
      </div>
    </div>
  );
};
