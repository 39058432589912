import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';
import { postFetch, postFetchAuth } from 'lib/fetch';
import { RoutePaths } from 'routes/paths';
import { NotifierTitle } from 'utils/enums';
import notifier from 'utils/Notifiers/Notifier';
import { ChangePasswordPayload, LoginPayload, SignUpPayload } from '../types/SignUpType';

const useSignUp = () => {
  const {
    mutate: signUpMutate,
    isLoading: signUpLoading,
    isSuccess: signUpSuccess,
    isError,
  } = useMutation(
    (payload: SignUpPayload) => {
      return postFetch(`register-corporation/`, payload);
    },
    {
      onSuccess: () => {
        return notifier.success(NotifierTitle.SIGNUP);
      },
      onError: (error) => {
        // handle sign up error
        const e = error as Error;
        return notifier.error(NotifierTitle.SIGNUP, e.message);
      },
    }
  );

  const formSubmit = (payload: SignUpPayload) => {
    signUpMutate(payload);
  };

  return { formSubmit, signUpMutate, signUpLoading, signUpSuccess, isError };
};

const useLogin = () => {
  const { setLoggedIn } = useAuth();
  const navigate = useNavigate();
  const useLoginMutation = useMutation(
    (payload: LoginPayload) => {
      return postFetch(`login/`, payload);
    },
    {
      onSuccess: (data) => {
        // handle successful sign up
        localStorage.setItem('token', data.key);
        notifier.success(NotifierTitle.LOGIN);
        setLoggedIn(true);
        navigate(RoutePaths.Dashboard, { replace: true });
      },
      onError: (error) => {
        // handle sign up error
        const e = error as Error;
        return notifier.error(NotifierTitle.LOGIN, e.message);
      },
    }
  );

  const { mutate: loginMutate, isLoading: loginLoading } = useLoginMutation;

  const formSubmit = (payload: LoginPayload) => {
    loginMutate(payload);
  };
  return { formSubmit, loginMutate, loginLoading };
};

const useUploadPhoto = () => {
  const useUploadPhotoMutation = useMutation(
    (payload: any) => {
      return postFetchAuth(`profile-picture/`, payload);
    },
    {
      onSuccess: () => {
        // handle successful sign up

        notifier.success(NotifierTitle.UPDATE_PROFILE_PICTURE);
      },
      onError: (error) => {
        // handle sign up error
        const e = error as Error;
        return notifier.error(NotifierTitle.UPDATE_PROFILE_PICTURE, e.message);
      },
    }
  );

  const { mutate: uploadMutate, isLoading: uploadLoading } = useUploadPhotoMutation;

  const formSubmit = (payload: any) => {
    uploadMutate(payload);
  };
  return { formSubmit, uploadLoading };
};

const useChangePassword = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const useChangePasswordMutation = useMutation(
    (payload: ChangePasswordPayload) => {
      return postFetchAuth(`change-password/`, payload);
    },
    {
      onSuccess: () => {
        // handle successful sign up

        notifier.success(NotifierTitle.CHANGEPASSWORD);
        logout();
        navigate(RoutePaths.DefaultPage, { replace: true });
      },
      onError: (error) => {
        // handle sign up error
        const e = error as Error;
        return notifier.error(NotifierTitle.CHANGEPASSWORD, e.message);
      },
    }
  );

  const { mutate: changePasswordMutate, isLoading: changePasswordLoading } = useChangePasswordMutation;

  const formSubmit = (payload: ChangePasswordPayload) => {
    changePasswordMutate(payload);
  };
  return { formSubmit, changePasswordLoading };
};

export { useSignUp, useLogin, useUploadPhoto, useChangePassword };
