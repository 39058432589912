import React from 'react';
import { Tabs, Typography } from 'antd';
import type { TabsProps } from 'antd';
import ChangePassword from './ChangePassword';
import Notification from './Notification';
import Profile from './Profile';

const ProfileSettings = () => {
  const onChange = (key: string) => {
    console.log(key);
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: `Profile`,
      children: <Profile />,
    },
    {
      key: '2',
      label: `Password`,
      children: <ChangePassword />,
    },
    {
      key: '3',
      label: `Notification`,
      children: <Notification />,
    },
    {
      key: '4',
      label: `Feedback`,
      children: `FeedBack Content`,
    },
  ];
  const { Title } = Typography;

  return (
    <>
      <Title className="text-secondaryDarkest !text-2xl font-semibold">Settings</Title>
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} className="min-h-[500px]" prefixCls="py-5" />
    </>
  );
};

export default ProfileSettings;
