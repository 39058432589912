/* eslint-disable react/jsx-props-no-spreading */
import React, { FC } from 'react';
import { Form, FormItemProps, Switch, SwitchProps } from 'antd';

type SwitchToggleProps = {
  label: string;
  switchProps: SwitchProps;
};

const SwitchToggle: FC<SwitchToggleProps & FormItemProps> = ({ label, switchProps, ...props }) => {
  return (
    <div className="flex flex-row gap-2 items-center  justify-between">
      <p className="font-normal text-base text-[#333333]">{label}</p>
      <Form.Item valuePropName="checked" {...props}>
        <Switch {...switchProps} />
      </Form.Item>
    </div>
  );
};

export { SwitchToggle };
