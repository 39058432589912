/* eslint-disable global-require */
import { useEffect } from 'react';
import { Row, Col, Form } from 'antd';
import AOS from 'aos';
import { Link, useNavigate } from 'react-router-dom';
import { RoutePaths } from 'routes/paths';
import { useGetInTouch } from 'services/services';
import { GetInTouchPayload } from 'services/types/GetInTouchType';
import { AppButton } from '../Button/AppButton';
import { Button } from '../Button/Button';
import { LabelInput } from '../Form';
import { Spacer } from '../Spacer';
import styles from './styles.module.scss';
import 'aos/dist/aos.css';

export const Footer = () => {
  const { formSubmit, getInTouchLoading } = useGetInTouch();
  const [formRef] = Form.useForm();

  const handleSubmit = (values: GetInTouchPayload) => {
    formSubmit({ ...values, formRef });
  };

  const navigate = useNavigate();

  const onRequestDemoClick = () => {
    navigate(RoutePaths.SignUp);
  };
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className={styles.footWrapper} data-aos="fade-up" data-aos-duration="300" data-aos-offset="100">
      <div className={styles.footerTop}>
        <div className="container">
          <Row align="middle" justify="space-between">
            <Col>
              <h1>{`Let's get moving together!`}</h1>
            </Col>
            <Col>
              <AppButton title="Sign up today" onClick={onRequestDemoClick} />
            </Col>
          </Row>
        </div>
      </div>
      <div className={styles.footer}>
        <div className="container">
          <Row gutter={[16, 32]}>
            <Col xs={24} sm={24} md={11} lg={11} xl={10}>
              <div className="logo">
                <Link to="/">
                  <img src={require('assets/FooterLogo.png')} alt="footer logo" />
                </Link>
              </div>
              <Spacer height="20px" />
              <div style={{ maxWidth: '280px' }}>
                <p className={styles.desc}>A world where the healthy decision is the easiest.</p>
              </div>
              <Spacer height="30px" />
              <Row gutter={[16, 16]} align="middle">
                <Col>
                  <p className={styles.getAppText}>Get the app</p>
                </Col>
              </Row>
              <div className="flex gap-4">
                <Button className="h-auto bg-transparent p-0 mt-4 border-none">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.getthenewlife.tnl&hl=en&gl=US"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={require('assets/playStore.png')} alt="google play button" className="h-11" />
                  </a>
                </Button>
                <Button className="h-auto bg-transparent p-0 mt-4 border-none">
                  <a
                    href="https://apps.apple.com/us/app/get-the-new-life/id1458514432"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={require('assets/appStore.png')} alt="google play button" className="h-11" />
                  </a>
                </Button>
              </div>
              <p className="text-white mt-4">Copyright @ GetNewLife, 2022</p>
            </Col>
            <Col xs={0} sm={0} md={{ span: 12, offset: 1 }} lg={{ span: 12, offset: 1 }} xl={{ span: 10, offset: 4 }}>
              <div style={{ maxWidth: '280px' }}>
                <h1>Get in Touch!</h1>
                <br />
                <p className={styles.desc}>A world where the healthy decision is the easiest.</p>
              </div>

              <Form onFinish={handleSubmit} form={formRef}>
                <div className="h-full w-full flex flex-col items-start justify-center ">
                  <LabelInput
                    name="fullName"
                    rules={[{ required: true, message: 'Please enter your full name' }]}
                    className="!w-full customInput"
                    inputProps={{
                      placeholder: 'Full Name',
                      className:
                        '!w-full !border-transparent !shadow-none !border-b-grayScale rounded-none mb-0 bg-[#141d34] text-white pl-0 mt-4 text-sm font-normal',
                    }}
                  />
                </div>

                <div className="h-full w-full flex flex-col items-start justify-center">
                  <LabelInput
                    name="email"
                    rules={[
                      { required: true, message: 'Please enter your Email Address' },
                      { type: 'email', message: 'The input is not valid E-mail!' },
                    ]}
                    className="!w-full customInput"
                    inputProps={{
                      placeholder: 'Email Address',

                      className:
                        '!w-full !border-transparent !shadow-none !border-b-grayScale rounded-none mb-0 bg-[#141d34] text-white pl-0 text-sm font-normal',
                    }}
                  />
                </div>
                <div className="h-full w-full flex flex-col items-start justify-center">
                  <LabelInput
                    name="message"
                    rules={[{ required: true, message: 'Please enter your message' }]}
                    className="!w-full customInput"
                    inputProps={{
                      placeholder: 'Message',

                      className:
                        '!w-full !border-transparent !shadow-none !border-b-grayScale rounded-none mb-0 bg-[#141d34] text-white pl-0 text-sm font-normal',
                    }}
                  />
                </div>

                <Button type="primary" className="primaryBtn" loading={getInTouchLoading} htmlType="submit">
                  Submit Form
                </Button>
              </Form>
            </Col>
            {/* FOR SMALLER DEVICES */}
            {/* <Col xs={24} sm={24} md={0}>
              <div style={{ maxWidth: '280px' }}>
                <p className={styles.desc}>A world where the healthy decision is the easiest.</p>
              </div>
            </Col> */}
          </Row>
        </div>
      </div>
      {/* <div className={styles.copyRight}>
        <p>Copyright @ GetNewLife, 2022</p>
      </div> */}
    </div>
  );
};
