import { FC, useEffect } from 'react';
import { useHeader } from 'context/HeaderContext';
import { HeroContainer } from 'features/home';
import { FAQs } from 'features/home/components/FAQs';
import { FewWords } from 'features/home/components/FewWords';
import { HowWeDoIt } from 'features/home/components/HowWeDoIt';
import ResourceAndInsights from 'features/home/components/ResourceAndInsights';
import { TrackCelebrate } from 'features/home/components/TrackCelebrate';
import { WhyNewLife } from 'features/home/components/WhyNewLife';

export const Home: FC = () => {
  const { setChangeHeaderColor } = useHeader();

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY >= 690) {
        setChangeHeaderColor(true);
      }
      if (window.scrollY <= 690) {
        setChangeHeaderColor(false);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setChangeHeaderColor]);

  return (
    <>
      <HeroContainer />
      <WhyNewLife />
      <HowWeDoIt />
      <TrackCelebrate />
      <FAQs />
      <FewWords />
      <ResourceAndInsights />
    </>
  );
};
