import { FC } from 'react';
import { Input as AntInput, InputProps as AntInputProps } from 'antd';
import clsx from 'clsx';

const PasswordInput: FC<AntInputProps> = ({ className, ...props }) => {
  return (
    <AntInput.Password
      className={clsx('px-[16px] py-[12px] rounded-lg h-12', className)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export { PasswordInput };
