import React, { useEffect } from 'react';
import { useHeader } from 'context/HeaderContext';
import { TopContent } from 'features/about/components/TopContent';

const About = () => {
  const { setChangeHeaderColor } = useHeader();

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY >= 690) {
        setChangeHeaderColor(true);
      }
      if (window.scrollY <= 690) {
        setChangeHeaderColor(false);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setChangeHeaderColor]);
  return <TopContent />;
};

export default About;
