import React, { useEffect, useState } from 'react';
import { Progress } from 'antd';
import { useAuth } from 'context/AuthContext';
import StepperForm from 'features/auth/signup/components/StepperForm';
import StepperProvider from 'features/auth/signup/context/StepperContext';

const SignUp = () => {
  const { setChangeHeader } = useAuth();
  const [percent, setPercent] = useState<number>(0);

  useEffect(() => {
    setChangeHeader(true);
  }, [setChangeHeader]);

  return (
    <div className="mt-20 bg-primaryLight">
      <Progress
        percent={percent}
        status="normal"
        showInfo={false}
        size="small"
        strokeWidth={4}
        strokeColor="#5CC9D8"
        trailColor="transparent"
        strokeLinecap="square"
        rootClassName="test"
        className="relative -top-2"
      />
      <div className="container">
        <StepperProvider>
          <StepperForm setPercent={setPercent} />
        </StepperProvider>
      </div>
    </div>
  );
};

export { SignUp };
