/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { Form } from 'antd';
import { Button } from 'components/ui/Button/Button';
import { LabelInput } from 'components/ui/Form';
import { UploadFormItem } from 'components/ui/upload';
import { useUploadPhoto } from 'features/auth/signup/services/services';
import { useGetUserDetail, useUpdateUserDetails } from 'features/dashboard/services/services';

const Profile = () => {
  const { formSubmit, uploadLoading } = useUploadPhoto();
  const [formPhotoRef] = Form.useForm();
  const [formProfileRef] = Form.useForm();
  const { userDetailsData } = useGetUserDetail({ enabled: true });
  const { formSubmit: profileUpdate, updateUserMutateLoading } = useUpdateUserDetails();

  useEffect(() => {
    if (userDetailsData) {
      const { name, address, email, profile_picture } = userDetailsData;
      formProfileRef?.setFieldsValue({
        name,
        address,
        email,
      });
      formPhotoRef?.setFieldsValue({
        profile_picture: {
          imageUrl: profile_picture,
        },
      });
    }
  }, [formPhotoRef, formProfileRef, userDetailsData]);

  const onFinish = () => {
    formProfileRef.validateFields().then((values) => {
      const file = formPhotoRef.getFieldValue('profile_picture')?.fileList?.[0] as any;
      if (file) {
        const formData = new FormData();
        formData.append('profile_picture', file, file.name);
        formSubmit(formData);
      }
      profileUpdate(values);
    });
  };

  return (
    <div className="mt-3 w-1/2">
      <Form layout="vertical" autoComplete="off" className="flex items-center" form={formPhotoRef}>
        <Form.Item
          name="profile_picture"
          rules={[{ required: true, message: 'Please upload your photo to continue' }]}
          className="mb-2 "
        >
          <UploadFormItem showCircle />
        </Form.Item>
      </Form>

      <Form layout="vertical" autoComplete="off" className="mt-4 flex flex-col gap-3" form={formProfileRef}>
        <LabelInput
          name="name"
          label="Full Name"
          rules={[{ required: true, message: 'Please input your name' }]}
          inputProps={{
            placeholder: 'Enter your Name',
          }}
          className="mb-0"
        />
        <LabelInput
          name="email"
          label="Email"
          rules={[
            { required: true, message: 'Please input your email address!' },
            { type: 'email', message: 'The input is not valid E-mail!' },
          ]}
          className="mb-0"
          inputProps={{
            type: 'email',
            placeholder: 'Email Address',
            disabled: true,
          }}
        />
        <LabelInput
          name="address"
          label="Location"
          rules={[{ required: false, message: 'Please input your location' }]}
          inputProps={{
            placeholder: 'Enter your Location',
          }}
          className="mb-0"
        />
      </Form>

      <div className="flex justify-start flex-2 mt-8">
        <Button
          type="primary"
          className="primaryBtn"
          onClick={onFinish}
          loading={uploadLoading || updateUserMutateLoading}
        >
          Save Profile
        </Button>
      </div>
    </div>
  );
};

export default Profile;
