import { Dispatch, SetStateAction, useEffect } from 'react';
import { Steps, Form } from 'antd';
import 'tailwindcss/tailwind.css';
import { useStepperContext } from '../context/StepperContext';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';
import Step8 from './Step8';

const StepperForm = ({ setPercent }: { setPercent: Dispatch<SetStateAction<number>> }) => {
  const { currentStep, formRef, handleSubmit } = useStepperContext();

  const steps = [
    {
      title: 'Step 1',
      content: <Step1 />,
    },
    {
      title: 'Step 2',
      content: <Step2 />,
    },
    {
      title: 'Step 3',
      content: <Step3 />,
    },
    {
      title: 'Step 4',
      content: <Step4 />,
    },
    {
      title: 'Step 5',
      content: <Step5 />,
    },
    {
      title: 'Step 6',
      content: <Step6 />,
    },
    {
      title: 'Step 7',
      content: <Step7 />,
    },
    {
      title: 'Step 8',
      content: <Step8 />,
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const percent = ((currentStep + 1) / steps.length) * 100;
  useEffect(() => {
    setPercent(percent);
  }, [percent, setPercent]);

  return (
    <>
      <Steps current={currentStep} items={items} className="hidden" />
      <Form form={formRef} layout="vertical" onFinish={handleSubmit}>
        <div className="p-8 pb-28">{steps[currentStep].content}</div>
      </Form>
    </>
  );
};

export default StepperForm;
