/* eslint-disable global-require */
import React from 'react';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Dropdown, Space, Typography } from 'antd';
import type { MenuProps } from 'antd';
import { Button } from 'components/ui/Button/Button';
import { SvgIconName } from 'icons/enums';
import { SvgIcons } from 'icons/svgIcons';
import { RoutePaths } from 'routes/paths';

interface UserStatProps {
  title: string;
  svgIcon: SvgIconName;
  dropdown?: boolean;
  text: string;
  link?: string;
  linkRoute?: RoutePaths;
  img: any;
}

const UserStats = ({ title, svgIcon, text, link, linkRoute, img, dropdown = false }: UserStatProps) => {
  const { Title, Text, Link } = Typography;

  const items: MenuProps['items'] = [
    {
      label: 'This Week',
      key: '1',
      icon: <UserOutlined />,
    },
  ];

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    console.log('click', e);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div className="border border-solid border-[#E0E0E0] rounded-lg p-8 flex flex-col w-[32%] relative ">
      <div className="mb-8 flex justify-between items-center">
        <SvgIcons iconName={svgIcon} width={36} height={36} />
        {dropdown && (
          <Dropdown menu={menuProps}>
            <Button>
              <Space>
                This Week
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        )}
      </div>

      <Title className="text-secondaryDarkest !text-6xl !font-normal !mb-7">{title}</Title>
      <Text className="text-[#5D5D5D] !font-normal text-xl leading-[160%] w-3/4 !mb-3">{text}</Text>
      <Link href={linkRoute} className="!font-normal text-xl leading-[160%] !mb-3 !underline">
        {link}
      </Link>
      <img src={img} alt="description of upload" className=" absolute bottom-0 right-0" />
    </div>
  );
};

export default UserStats;
