/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable global-require */
import { FC, useEffect, useRef, useState } from 'react';
import AOS from 'aos';
import clsx from 'clsx';
import Slider, { Settings } from 'react-slick';
import { ReviewCard } from 'components/ui/Card/ReviewCard';
import styles from './styles.module.scss';
import 'aos/dist/aos.css';

const settings: Settings = {
  infinite: true,
  dots: false,
  speed: 500,
  initialSlide: 0,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  vertical: true,
  verticalSwiping: true,
  centerMode: true,
  centerPadding: '0',
  adaptiveHeight: true,

  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const FewWords: FC = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const slider = useRef<any>(null);

  const sliderCounts = new Array(6).fill(0).map((_value, index) => index);
  const [slideIndex, setSlideIndex] = useState<number>(0);

  return (
    <div className={styles.fewWords}>
      <div className={styles.fewWords__wrap}>
        <div className="flex justify-between">
          <div className="w-[40%]" data-aos="fade-up" data-aos-duration="300">
            <h1 className="text-5xl text-secondary leading-[64px]">{`Few words from\n our New Lifers.`}</h1>
            <p className="text-xl leading-[32px] font-normal mt-3 text-secondaryLight">{`Here are the words of those who leveraged\n community and technology to reach their health goals.`}</p>

            <div className="flex gap-4 mt-8">
              {sliderCounts.map((count) => (
                <div
                  key={count}
                  className={clsx(styles.dot__wrap, count === slideIndex && styles.dot__select, 'cursor-pointer')}
                  onClick={() => slider.current.slickGoTo(count)}
                  aria-hidden
                />
              ))}
            </div>
          </div>
          <div className="w-[60%]" data-aos="fade-up" data-aos-duration="300" data-aos-delay="300">
            <Slider
              ref={slider}
              {...settings}
              beforeChange={(__current, next) => setSlideIndex(next)}
              className="testiSlider"
            >
              <div>
                <ReviewCard
                  age="71"
                  desc="I am 71 years old and I have lost nearly 20 pounds now. I am also no longer on my blood pressure medication. I live around people right now who have so many health issues and I have learned so much from this Challenge so that now I can be there to answer their questions."
                  designation="Retired Nurse"
                  imageUrl="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRblGHmIA70kc9T4UJy-AFc0YLcnPpu5kwR2Q&usqp=CAU"
                  name="Mary"
                />
              </div>

              <div>
                <ReviewCard
                  age="48"
                  desc="It is not easy to put my progress in words, because it is beyond weight loss, smooth skin, better sleep and so on. Yes I lost 20 pounds, since late October, my skin is ra- diant, I have very restful sleep and my energy is the best I can remember. What I am most thankful for is that The New Life Challenge “agrees with me”. Everything from the top of my head to my toes feels better, and works better."
                  designation="Housewife"
                  imageUrl="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRblGHmIA70kc9T4UJy-AFc0YLcnPpu5kwR2Q&usqp=CAU"
                  name="Shirlene"
                />
              </div>
              <div>
                <ReviewCard
                  age="20"
                  desc="This program changed so many aspects of my life. I am so thankful!"
                  designation="Student"
                  imageUrl="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRblGHmIA70kc9T4UJy-AFc0YLcnPpu5kwR2Q&usqp=CAU"
                  name="Steve"
                />
              </div>
              <div>
                <ReviewCard
                  age="35"
                  desc="I took The New Life Challenge in the summer of 2011 with my mother who was having some serious health issues. It was the beginning of a healthier happier life. I not only had a weight loss of 24 pounds, but found myself thinking much clearer and having more energy. We saw some wonderful results in my mother’s health. The principles laid out in this app have changed our lives forever"
                  designation="Social Worker"
                  imageUrl="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRblGHmIA70kc9T4UJy-AFc0YLcnPpu5kwR2Q&usqp=CAU"
                  name="Johanna"
                />
              </div>
              <div>
                <ReviewCard
                  age="55"
                  desc="This is day 21 and I’ve lost 25 lbs. Wow, this has been a great experience. Friends and co-workers see many positive changes and are asking questions."
                  designation="Secretary"
                  imageUrl="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRblGHmIA70kc9T4UJy-AFc0YLcnPpu5kwR2Q&usqp=CAU"
                  name="Pamela"
                />
              </div>
              <div>
                <ReviewCard
                  age="63"
                  desc="When I took The New Life Challenge this past summer I had serious problems with poor circulation and was on the verge of amputation of my lower right leg. With a lot of prayer and support from other New Life Challenge members, I was given my life back through this wonderful program. I lost 33 pounds, started feeling good physically, sharp mentally, and a deep fulfillment in my soul. Most of all, I now have tools to help others"
                  designation=""
                  imageUrl="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRblGHmIA70kc9T4UJy-AFc0YLcnPpu5kwR2Q&usqp=CAU"
                  name="Anna"
                />
              </div>
            </Slider>
          </div>
        </div>
        {/* <Spacer height="100px" /> */}
      </div>
    </div>
  );
};
