import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ConfigProvider } from 'antd';
import ReactDOM from 'react-dom/client';
import AuthProvider from 'context/AuthContext';
import HeaderProvider from 'context/HeaderContext';
import App from './App';
import { antdTheme } from './data/antdTheme';
import reportWebVitals from './reportWebVitals';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 200 * 1000,
    },
  },
});
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY as string);
root.render(
  <React.StrictMode>
    <ConfigProvider theme={antdTheme}>
      <QueryClientProvider client={queryClient}>
        <>
          <AuthProvider>
            <Elements stripe={stripePromise}>
              <HeaderProvider>
                <App />
              </HeaderProvider>
            </Elements>
          </AuthProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </>
      </QueryClientProvider>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
