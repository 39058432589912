import { FC } from 'react';
import clsx from 'clsx';
import { FunctionWithNoParam } from 'utils/types';
import styles from './styles.module.scss';

export interface AppButtonButtonProps {
  title: string;
  onClick?: FunctionWithNoParam;
  disabled?: boolean;
  loading?: boolean;
  btnStyle?: string;
  textStyle?: string;
}

export const AppButton: FC<AppButtonButtonProps> = ({
  title,
  onClick,
  disabled,
  loading,
  btnStyle,
  textStyle,
}: AppButtonButtonProps) => {
  return (
    <div
      className={clsx(styles.app__button, btnStyle)}
      onClick={disabled || loading ? () => null : onClick}
      role="button"
      onKeyPress={disabled || loading ? () => null : onClick}
      tabIndex={0}
    >
      <p className={clsx(textStyle)}>{title}</p>
    </div>
  );
};
