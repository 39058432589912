import { FC, useEffect, useReducer, useRef } from 'react';
import { Button } from 'antd';
import clsx from 'clsx';
import { FunctionWithParam } from 'utils/types';
import { UploadActionProp, UploadStateProp, UploadDropZone } from './UploadDropZone';

export interface UploadImageFormItemProp {
  fileList?: FileList | null;
  imageUrl?: string | null;
}

export interface UploadFormItemProps {
  onChange?: FunctionWithParam<UploadImageFormItemProp>;
  value?: UploadImageFormItemProp;
  showCircle?: boolean;
  companyProfile?: boolean;
  id?: string;
}

const reducer = (state: UploadStateProp, action: UploadActionProp): UploadStateProp => {
  switch (action.type) {
    case 'SET_IN_DROP_ZONE':
      return { ...state, inDropZone: action.inDropZone };
    case 'ADD_FILE_TO_LIST':
      return { ...state, fileList: action.files as unknown as FileList };
    case 'REMOVE_FILE_AND_IMAGE_URL':
      return { ...state, imageUrl: null, fileList: undefined, inDropZone: false };
    case 'ADD_IMAGE_URL':
      return { ...state, imageUrl: action.imageUrl };
    default:
      return state;
  }
};

export const UploadFormItem: FC<UploadFormItemProps> = ({
  onChange,
  value,
  showCircle,
  companyProfile,
  id,
}: UploadFormItemProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [data, dispatch] = useReducer(reducer, {
    inDropZone: false,
  });

  useEffect(() => {
    if (data.fileList && value?.fileList !== data.fileList) {
      onChange?.({ fileList: data.fileList });
    }
  }, [data, onChange, value?.fileList]);

  useEffect(() => {
    if (value?.imageUrl && data?.imageUrl !== value?.imageUrl) {
      dispatch({
        type: 'ADD_IMAGE_URL',
        imageUrl: value.imageUrl,
      });
    }
  }, [value?.imageUrl, data]);

  return (
    <div className={clsx('flex items-center gap-2')}>
      <UploadDropZone
        data={data}
        onImageChange={dispatch}
        ref={inputRef}
        showCircle={showCircle}
        companyProfile={companyProfile}
        id={id}
      />
      <div className="flex flex-row align-middle gap-2">
        <Button
          type="primary"
          className="text-center primaryBtn"
          htmlType="button"
          onClick={() => {
            inputRef.current?.click();
          }}
        >
          Upload Picture
        </Button>
        <Button
          type="primary"
          className="text-center rounded-full py-2 px-6 h-auto bg-transparent text-primary border-primary"
          htmlType="button"
          onClick={() => {
            dispatch({
              type: 'REMOVE_FILE_AND_IMAGE_URL',
            });
            onChange?.({
              fileList: null,
              imageUrl: null,
            });
          }}
        >
          <span className="pl-2">Delete</span>
        </Button>
      </div>
    </div>
  );
};
