import { FC } from 'react';
import { Col, Row } from 'antd';
import styles from './styles.module.scss';

export interface ReviewCardProps {
  desc: string;
  name: string;
  imageUrl: string;
  age: string;
  designation: string;
}

export const ReviewCard: FC<ReviewCardProps> = ({ desc, name, age, designation, imageUrl }: ReviewCardProps) => {
  return (
    <div className="py-3">
      <div className={styles.review}>
        <div>
          <p className={styles.desc}>{desc}</p>
        </div>
        <Row align="middle" gutter={[16, 16]}>
          <Col>
            <img src={imageUrl} alt="reviewer logo" width={58} height={58} />
          </Col>
          <Col>
            <p className={styles.details}>
              <span className={styles.name}>{name}</span>, {age}, {designation}
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};
