export const notificationSettingsData = [
  {
    name: 'exercise',
    label: 'Exercise reminders',
  },
  {
    name: 'challenges',
    label: 'Daily updates on challenges',
  },
  {
    name: 'community',
    label: 'Community updates',
  },
  {
    name: 'report',
    label: 'Weekly report',
  },
  {
    name: 'update',
    label: 'Accountability Partner’s updates',
  },
];
