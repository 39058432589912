import { FC, PropsWithChildren } from 'react';
import { Layout } from 'antd';
import { Footer } from 'components/ui/Footer';
import { Header } from 'components/ui/Header';
import styles from './styles.module.scss';

const { Header: AntdHeader, Content } = Layout;

export const UnAuthLoyout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Layout className={styles.userLayout}>
      <AntdHeader className={`${styles.header} ${styles.authHeader}`}>
        <Header changeColor />
      </AntdHeader>
      <Content className={styles.content}>{children}</Content>
      <Footer />
    </Layout>
  );
};
