import { FAQCardProps } from 'components/ui/Card';
import { FAQType } from 'utils/enums';

export const FaqTypes: FAQType[] = [
  FAQType.ALL,
  FAQType.COMPANY,
  FAQType.THE_NEW_LIFE_APP,
  FAQType.BLOG_POST,
  FAQType.CHALLENGES,
  FAQType.HEALTH_TIPS,
  FAQType.PROCESS,
];

export const faqsDatas: FAQCardProps[] = [
  {
    title: 'What is The New Life Challenge?',
    desc: 'It is a 10-week wholistic (yes we spelled this way intentionally :D) welness program designed to facilitate the development of 10 healthy habits based upon the longest living Americans and scientific research.',
    faqTypes: [FAQType.COMPANY],
  },
  {
    title: 'What precautions should I take if I have a soecific medical condition?',
    desc: 'Ensure that you are in touch with your primary care physician about your involvement in the program to make sure you are not harming yourself and instead heading yourself.',
    faqTypes: [FAQType.HEALTH_TIPS],
  },
  {
    title: 'Is this program considered medical advice?',
    desc: 'No, we are not physicians and so if you experience any discomfort while going through the TNLC program, please contact your physician immediately.',
    faqTypes: [FAQType.COMPANY, FAQType.HEALTH_TIPS, FAQType.PROCESS],
  },
  {
    title: 'How does the mobile app calculate my health score and challenges?',
    desc: `The health score is uique algorighmdeveloped by The New Life to give each health factor its proper weight based upon its infulence on your overall health. Thus nutrition, water, and temperance have the largest infulence. followed by exervise, and then other habits. Your challenges progressively grow. Your health score is not about perfection, but your successful completion of the challenge. It is an average of all your challenges combined.`,
    faqTypes: [FAQType.THE_NEW_LIFE_APP],
  },
  {
    title: 'How do you know that these lifestyle habits can add 7-10 years to my life?',
    desc: '7-10 years is the average number of years that the occupants of the North American Blue Zone in Loma Linda, CA live longer than the rest of us. the only difference between them and you is their habits of living. Which we have encapsulated in The New Life acronym. There are, of course, other factor such as genetics that may infulence this number higher or slightly lower.',
    faqTypes: [FAQType.HEALTH_TIPS],
  },
  {
    title: 'Is there health coaching?',
    desc: 'Yes, for a premium price we can have a live coach contact you to even further adapt the program to your needs and circumstances. However. we are excited to also note that we will be adding digital coaching into our next iteration of the app: D',
    faqTypes: [FAQType.COMPANY],
  },
  {
    title: 'How much time should I spend on challenges that do not have a set time allotted to them?',
    desc: 'For each of these challenges the goal is to engage in the activity or complete the task with complete focus and sincerity.',
    faqTypes: [FAQType.CHALLENGES, FAQType.HEALTH_TIPS, FAQType.PROCESS],
  },
  {
    title: 'What kind of comments are we looking for on the blog?',
    desc: 'We are simply looking for your reaction and practical uses of the information for yourself. Comments like, “I didn’t know that...” or “I feel that this idea is...” or “Wow! great article”. Now word limit, just your candid thoughts, we are not sensitive :D',
    faqTypes: [FAQType.BLOG_POST],
  },
  {
    title: 'Do we have to read and comment on each blog post?',
    desc: 'Yes, In an ideal world, this would be no problem, but we understand things happen. however, with the 7-Day point boost (see rules of the game section) you can gain immunity to a slip or if you want a break.',
    faqTypes: [FAQType.BLOG_POST],
  },
];
