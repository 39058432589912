import { createContext, Dispatch, ReactElement, SetStateAction, useContext, useMemo, useState } from 'react';

interface AuthContextType {
  loggedIn: boolean;
  logout: () => Promise<void>;
  setLoggedIn: Dispatch<SetStateAction<boolean>>;
  changeHeader: boolean;
  setChangeHeader: Dispatch<SetStateAction<boolean>>;
}

const AuthContext = createContext({} as AuthContextType);

interface Props {
  children: ReactElement;
}

const AuthProvider = ({ children }: Props): ReactElement => {
  const [loggedIn, setLoggedIn] = useState<boolean>(!!localStorage.getItem('token'));

  const [changeHeader, setChangeHeader] = useState<boolean>(false);

  const logout = async () => {
    // async task
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    setLoggedIn(false);
  };

  const value = useMemo(
    () => ({
      logout,
      loggedIn,
      setLoggedIn,
      changeHeader,
      setChangeHeader,
    }),
    [loggedIn, changeHeader]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
export default AuthProvider;

export const useAuth = (): AuthContextType => useContext(AuthContext);
