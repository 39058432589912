/* eslint-disable @typescript-eslint/no-explicit-any */
import { RefObject } from 'react';

export default function useClickOutside(ref: RefObject<HTMLDivElement>, cb: { (): void; (arg0: unknown): void }) {
  function handleClickOutside(event: any) {
    if (ref.current && !ref.current.contains(event.target)) {
      // alert('You clicked outside of me!');
      cb(event);
    }
  }

  // Bind the event listener
  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    // Unbind the event listener on clean up
    document.removeEventListener('mousedown', handleClickOutside);
  };
}
