import React from 'react';
import { Button } from 'components/ui/Button/Button';
import { Card } from 'components/ui/Card/Card';
import { LabelInput } from 'components/ui/Form';
import { useStepperContext } from '../context/StepperContext';

const Step2 = () => {
  const { handleNext, handlePrev, formRef } = useStepperContext();

  const handleClick = (buttonValue: number) => {
    formRef.setFieldValue('communityNumber', buttonValue);
  };

  const limit = formRef.getFieldValue('limit');

  const maxValue = (communityPlan: number) => {
    switch (communityPlan) {
      case 200:
        return 200;
      case 500:
        return 500;
      case -1:
        return Number.MAX_SAFE_INTEGER;
      default:
        return Number.MAX_SAFE_INTEGER;
    }
  };

  const minValue = (communityPlan: number) => {
    switch (communityPlan) {
      case 200:
        return 1;
      case 500:
        return 201;
      case -1:
        return 501;
      default:
        return Number.MAX_SAFE_INTEGER;
    }
  };

  return (
    <>
      <p className="stepFormTitle">2. How many New Lifers in your community?</p>
      <Card className="w-3/4 m-auto h-[522px]">
        <div className="flex flex-col h-full w-full">
          <div className="flex justify-start">
            <p onClick={handlePrev} className="cursor-pointer  text-lg" aria-hidden>
              <span className="text-primary">{`<-- `}</span>
              Back : Select Community Member
            </p>
          </div>
          <div className="h-full w-full flex flex-col items-start justify-center">
            <LabelInput
              name="communityNumber"
              rules={[
                { required: true, message: 'Please enter the number of people in the community' },

                {
                  type: 'integer',
                  max: maxValue(limit),
                  min: minValue(limit),
                  message: `Value must be between ${minValue(limit)} and ${maxValue(limit)}`,
                },
              ]}
              className="!w-full"
              inputType="number"
              inputNumberProps={{
                placeholder: 'Enter a number of people in community',
                className: '!w-full !border-transparent !shadow-none !border-b-grayScale rounded-none mb-0',
                min: 1,
                max: maxValue(limit),
              }}
            />
            <p className="text-base text-grayScale font-light mb-3">or Select thest numbers.</p>
            <div className="flex gap-3">
              {limit === 200 && (
                <>
                  <Button onClick={() => handleClick(25)} className="text-secondaryLight">
                    25
                  </Button>
                  <Button onClick={() => handleClick(50)} className="text-secondaryLight">
                    50
                  </Button>
                  <Button onClick={() => handleClick(75)} className="text-secondaryLight">
                    75
                  </Button>
                  <Button onClick={() => handleClick(100)} className="text-secondaryLight">
                    100
                  </Button>
                  <Button onClick={() => handleClick(200)} className="text-secondaryLight">
                    200
                  </Button>
                </>
              )}
              {limit === 500 && (
                <>
                  <Button onClick={() => handleClick(300)} className="text-secondaryLight">
                    250
                  </Button>
                  <Button onClick={() => handleClick(300)} className="text-secondaryLight">
                    300
                  </Button>
                  <Button onClick={() => handleClick(500)} className="text-secondaryLight">
                    500
                  </Button>
                </>
              )}
              {limit === -1 && (
                <>
                  <Button onClick={() => handleClick(600)} className="text-secondaryLight">
                    550
                  </Button>
                  <Button onClick={() => handleClick(600)} className="text-secondaryLight">
                    600
                  </Button>
                  <Button onClick={() => handleClick(600)} className="text-secondaryLight">
                    700
                  </Button>
                </>
              )}
            </div>
          </div>
          <div className="flex justify-end">
            <Button type="primary" onClick={handleNext} className="primaryBtn">
              Continue
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default Step2;
