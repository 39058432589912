/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { ComponentType } from 'react';
import { RoutePaths } from './paths';

export enum RouteType {
  ALLACCESS = 'ALLACCESS',
  AUTHORIZED = 'AUTHORIZED',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export interface ConfigRoute {
  feature: string;
  routeType: RouteType;
  props: {
    path: RoutePaths;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: ComponentType<any>;
    title: string;
  };
}

export interface RootRouterProps {
  routes: ConfigRoute[];
}
