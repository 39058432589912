import { FC, PropsWithChildren } from 'react';
import { Modal as AntdModal } from 'antd';
import { FunctionWithNoParam } from 'utils/types';

export interface ModalProps {
  visible: boolean;
  onCancel?: FunctionWithNoParam;
  width?: number;
  destroyOnClose?: boolean;
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({
  children,
  visible,
  onCancel,
  width = 384,
  destroyOnClose = true,
}) => {
  return (
    <AntdModal
      centered
      open={visible}
      className="bg-white shadow-xl border border-black__15"
      maskStyle={{
        background: 'rgba(250, 250, 250, 85%)',
      }}
      footer={null}
      width={width}
      onCancel={onCancel}
      destroyOnClose={destroyOnClose}
      closable
    >
      {children}
    </AntdModal>
  );
};
