export enum RoutePaths {
  DefaultPage = '/',
  Login = '/auth/login',
  SignUp = '/auth/signUp',
  Protected = '/protected',
  ChangePassword = '/change-password',
  UserManagement = '/user-management',
  Dashboard = '/dashboard',
  Settings = '/settings',
  Collaborator = '/collaborators',
  About = '/about',
  Faq = '/faq',
}
