import { FC } from 'react';
import styles from './styles.module.scss';

export interface HeadingTextProps {
  title: string;
  desc: string;
}

export const HeadingText: FC<HeadingTextProps> = ({ desc, title }: HeadingTextProps) => {
  return (
    <div className={styles.headingText__text__header}>
      <h1 className={styles.headingText__text__top}>{title}</h1>
      <h1 className={styles.headingText__text__bottom}>{desc}</h1>
    </div>
  );
};
