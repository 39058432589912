import React from 'react';
import { SwitchToggle } from 'components/ui/switch';
import { notificationSettingsData } from '../data/notificationSettingsData';

const Notification = () => {
  return (
    <div className="mt-3 w-1/2">
      <p className="mb-6 text-sm !font-normal text-[#767676]">Send Notification for</p>
      <div className="flex flex-col gap-y-6 flex-wrap">
        {notificationSettingsData.map((data) => (
          <SwitchToggle
            key={data.name}
            label={data.label}
            className="relative h-8 !mb-0"
            switchProps={{ className: 'bg-black__20' }}
            name={data.name}
          />
        ))}
      </div>
    </div>
  );
};

export default Notification;
