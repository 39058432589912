import { errorMessages } from './en';
import { NotifierTitle, Orientation } from './enums';
// Define all pure funtions here
import { ErrorObject, Nullable } from './types';

export const getOrientation = (width: number, height: number): Orientation =>
  // eslint-disable-next-line no-nested-ternary
  width > height ? Orientation.LANDSCAPE : width < height ? Orientation.PORTRAIT : Orientation.SQUARE;

export const generateErrorMessage = (title: NotifierTitle, description?: string): ErrorObject => ({
  message: errorMessages.default.message.replace('~~~', title),
  description: description || errorMessages.default.description.replace('~~~', title),
});

export const passwordRegex = /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?).{8,}$/;
export const containsLowerCharacterRegex = /.*[a-z].*/;
export const containsUpperCharacterRegex = /.*[A-Z].*/;
// eslint-disable-next-line no-useless-escape
export const containsSpecialCharacterRegex = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
export const containsDigitRegex = /\d/;

// get custom data for week/months...

export enum SortDate {
  Week = 'Week',
  Month = 'Month',
  Days = 'Day',
}

interface CustomDataProps {
  data: any[];
  sortBy: SortDate;
}

export const customData = ({ data, sortBy }: CustomDataProps) => {
  const noOfDays = () => {
    switch (sortBy) {
      case SortDate.Week:
        return 7;
      case SortDate.Month:
        return 30;
      default:
        return 1;
    }
  };

  const groupedData = data.reduce((acc: any, curr: any, index: number) => {
    const groupIndex = Math.floor(index / noOfDays()); // Divide the data into groups of three days each
    const key = `${sortBy}-${groupIndex + 1}`; // Use a custom key for each group

    if (!acc[key]) {
      acc[key] = { completed_users: 0 }; // Initialize the group with completedUsers set to 0
    }

    if (index % 7 < 7) {
      // Add completedUsers for each group of three days
      acc[key].completed_users += curr.completed_users;
    }

    return acc;
  }, {});

  return Object.keys(groupedData).map((key) => ({
    date: key,
    completed_users: groupedData[key].completed_users,
  }));
};

export const fullNameRegex = /^[A-Za-z\s]+$/;

export const checkForWhiteSpace = (_: unknown, value?: Nullable<string>) => {
  if (value && value.trim().length === 0) {
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('Please enter valid full name');
  }
  return Promise.resolve();
};

export const phoneNumberRegex = /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;
