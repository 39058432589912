import React, { useState } from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps, Space } from 'antd';
import clsx from 'clsx';
import { Button } from 'components/ui/Button/Button';
import { Spacer } from 'components/ui/Spacer';
import { StatName } from 'utils/enums';
import { SortDate } from 'utils/utilFunctions';
import Charts from './Charts';

const Challenges = ({ stats = [] }: any) => {
  const [statName, setStatName] = useState<string>('Exercise');
  const [statType, setStatType] = useState<string>('exercise');
  const [soryByType, setSortByType] = useState<SortDate>(SortDate.Week);
  const items: MenuProps['items'] = [
    {
      label: 'Day',
      key: '1',
      onClick: () => setSortByType(SortDate.Days),
    },

    {
      label: 'Week',
      key: '2',
      onClick: () => setSortByType(SortDate.Week),
    },

    {
      label: 'Month',
      key: '3',
      onClick: () => setSortByType(SortDate.Month),
    },
  ];

  const menuProps = {
    items,
  };

  const onChallengeStatClickhandler = (stat: string) => {
    setStatName((StatName as { [key: string]: string })[stat]);
    setStatType(stat);
  };

  const getStatData = stats[statType];

  return (
    <div className="border border-solid border-[#E0E0E0] rounded-lg p-8 flex flex-col w-full justify-between">
      <div className="flex justify-between">
        <h1 className="font-medium text-4xl leading-[54px] text-secondaryDarkest">Challenge Stats</h1>
      </div>
      <Spacer height="30px" />

      <div className="flex justify-between">
        <h1 className="font-medium text-2xl  text-secondary">3rd Week | Day 18</h1>
        <Dropdown menu={menuProps}>
          <Button>
            <Space>
              {`Sort By: ${soryByType}`}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </div>
      <Spacer height="25px" />

      <div className="flex gap-x-3 gap-y-4 flex-wrap">
        {Object.keys(stats)?.map((stat) => (
          <Button
            className={clsx(
              'border-[#ABABAB] text-[#ABABAB;] font-semibold uppercase h-9 px-8',
              statName === (StatName as { [key: string]: string })[stat] && '!border-primary !text-primary'
            )}
            key={stat}
            onClick={() => onChallengeStatClickhandler(stat)}
          >
            {(StatName as { [key: string]: string })[stat]}
          </Button>
        ))}
      </div>
      <Spacer height="30px" />
      <Charts data={getStatData} statName={statType} soryByType={soryByType} />
    </div>
  );
};

export default Challenges;
