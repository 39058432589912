import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { delFetchAuth, getFetchAuth, patchFetchAuth } from 'lib/fetch';
import { NotifierTitle } from 'utils/enums';
import notifier from 'utils/Notifiers/Notifier';

const useCorporationDetails = () => {
  const useCorporationDetailsQuery = useQuery(
    ['corporationDetails'],
    () => {
      return getFetchAuth(`corporation/`);
    },
    {
      onSuccess: () => {
        // handle successful sign up

        notifier.success(NotifierTitle.CORPORATION_DETAILS);
      },
      onError: (error) => {
        // handle sign up error
        const e = error as Error;
        return notifier.error(NotifierTitle.CORPORATION_DETAILS, e.message);
      },
    }
  );

  const { data: corporationDetailsData, isLoading: corporationDetailsLoading } = useCorporationDetailsQuery;
  return { corporationDetailsData, corporationDetailsLoading };
};

const useGetUserDetail = ({ enabled = true }: { enabled?: boolean }) => {
  const useGetUserDetailQuery = useQuery(
    ['userDetail'],
    () => {
      return getFetchAuth(`user/`);
    },

    {
      enabled,
      onSuccess: () => {
        // handle successful sign up
      },
      onError: () => {
        // handle sign up error
        // const e = error as Error;
        // console.log(e);
      },
    }
  );

  const { data: userDetailsData, isLoading: userDetailsLoading } = useGetUserDetailQuery;
  return { userDetailsData, userDetailsLoading };
};

const useGetUserFeedbacks = () => {
  const useGetUserFeedbacksQuery = useQuery(
    ['userFeedBack'],
    () => {
      return getFetchAuth(`feedbacks/`);
    },

    {
      onSuccess: () => {
        // handle successful sign up
        // console.log(data);
      },
      onError: () => {
        // handle sign up error
        // const e = error as Error;
        // console.log(e);
      },
    }
  );

  const { data: userFeedbacksData, isLoading: userFeedbacksDataLoading } = useGetUserFeedbacksQuery;
  return { userFeedbacksData, userFeedbacksDataLoading };
};

const useUpdateUserDetails = () => {
  const updateUserDetailsMutation = useMutation(
    (payload: any) => {
      return patchFetchAuth(`user/`, { name: payload.name, address: payload.address });
    },
    {
      onSuccess: () => {
        // handle successful sign up
        // console.log(data);
        notifier.success(NotifierTitle.UPDATE_PROFILE);
      },
      onError: (error) => {
        // handle sign up error
        const e = error as Error;
        return notifier.error(NotifierTitle.UPDATE_PROFILE, e.message);
      },
    }
  );

  const { mutate: updateUserMutate, isLoading: updateUserMutateLoading } = updateUserDetailsMutation;

  const formSubmit = (payload: any) => {
    updateUserMutate(payload);
  };
  return { formSubmit, updateUserMutateLoading };
};

const useDeleteUsers = () => {
  const queryClient = useQueryClient();

  const deleteUsersMutation = useMutation(
    (payload: any) => {
      return delFetchAuth(`users/`, payload);
    },
    {
      onSuccess: () => {
        // handle successful sign up

        queryClient.invalidateQueries(['listUsers']);
        notifier.success(NotifierTitle.DELETE_USERS);
      },
      onError: (error) => {
        // handle sign up error
        const e = error as Error;
        return notifier.error(NotifierTitle.DELETE_USERS, e.message);
      },
    }
  );

  const { mutate: deleteUserMutate, isLoading: deleteUserMutateLoading } = deleteUsersMutation;

  const formSubmit = (payload: any) => {
    deleteUserMutate(payload);
  };
  return { formSubmit, deleteUserMutateLoading };
};

export { useCorporationDetails, useGetUserDetail, useUpdateUserDetails, useDeleteUsers, useGetUserFeedbacks };
