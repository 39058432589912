/* eslint-disable no-unused-vars */
export enum SvgIconName {
  User = 'user',
  MedalStar = 'medalStar',
  Apple = 'apple',
  Educate = 'educate',
  Exercise = 'exercise',
  Invest = 'invest',
  Live = 'live',
  Sleep = 'sleep',
  Sunlight = 'sunlight',
  Trust = 'trust',
  Water = 'water',
}
