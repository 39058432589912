import React, { useState } from 'react';
import { Typography } from 'antd';
import { Button } from 'components/ui/Button/Button';
import FullPageLoader from 'components/ui/Loader/FullPageLoader';
import AddUsers from 'features/userManagement/components/AddUsers';
import { useListCordinators } from 'features/userManagement/services/services';
import AdminBox from './AdminBox';

const Collab = () => {
  const { listCordinatorsData, listCordinatorsLoading } = useListCordinators();
  const [addUserModal, setAddUserModal] = useState<boolean>(false);

  const closeAddUserModal = () => {
    setAddUserModal(false);
  };

  const requestAddUserHandler = () => {
    setAddUserModal(true);
  };
  const { Title } = Typography;

  if (listCordinatorsLoading) {
    return <FullPageLoader />;
  }

  return (
    <>
      <div className="flex justify-between items-center mb-10">
        <Title className="text-secondaryDarkest !text-2xl font-semibold">Collaborators</Title>
        <Button type="primary" className="primaryBtn h-11" onClick={requestAddUserHandler}>
          +Add Collaborators
        </Button>
        <AddUsers visible={addUserModal} onClose={closeAddUserModal} type="coordinators" />
      </div>
      <Title className=" !text-xl font-semibold text-[#0E1323] opacity-60">Super Admin</Title>

      {listCordinatorsData && listCordinatorsData.length !== 0 && (
        <>
          {listCordinatorsData
            .filter((coordinator: any) => coordinator.is_super_admin === true)
            .map((superAdmin: any) => (
              <AdminBox
                key={superAdmin.key}
                community_code={superAdmin.community_code}
                email={superAdmin.email}
                name={superAdmin.name}
                phone_number={superAdmin.phone_number || 'none'}
              />
            ))}
        </>
      )}

      <Title className=" !text-xl font-semibold text-[#0E1323] opacity-60 mt-8">Admin</Title>

      {listCordinatorsData && listCordinatorsData.length !== 0 && (
        <div className="flex gap-4">
          {listCordinatorsData
            .filter((coordinator: any) => coordinator.is_super_admin === false)
            .map((superAdmin: any) => (
              <AdminBox
                key={superAdmin.key}
                community_code={superAdmin.community_code}
                email={superAdmin.email}
                name={superAdmin.name}
                phone_number={superAdmin.phone_number || 'none'}
              />
            ))}
        </div>
      )}
    </>
  );
};

export default Collab;
