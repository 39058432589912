/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { Button } from 'components/ui/Button/Button';
import { Card } from 'components/ui/Card/Card';
import { LabelInput } from 'components/ui/Form';
import { checkForWhiteSpace, fullNameRegex, phoneNumberRegex } from 'utils/utilFunctions';
import { useStepperContext } from '../context/StepperContext';

const Step5 = () => {
  const { handleNext, handlePrev } = useStepperContext();

  return (
    <>
      <p className="stepFormTitle ">5. Who are the coordinators for your community?</p>
      <Card className="w-3/4 m-auto h-[522px]">
        <div className="flex flex-col h-full w-full">
          <div className="flex justify-start">
            <p onClick={handlePrev} className="cursor-pointer text-lg" aria-hidden>
              <span className="text-primary ">{`<-- `}</span>
              Back: Community name
            </p>
          </div>
          <div className="h-full w-full flex flex-col items-start justify-center">
            <Form.List name="coordinators" initialValue={[undefined]}>
              {(fields, { add, remove }) => (
                <div className="flex flex-col w-full">
                  {fields.map(({ key, name, ...restField }, index, arr) => (
                    <div key={key}>
                      <div className="flex w-full gap-4">
                        <LabelInput
                          {...restField}
                          name={[name, 'name']}
                          rules={[
                            { required: true, message: 'Enter Full Name' },

                            {
                              pattern: fullNameRegex,
                              message: 'Please enter valid full name',
                            },
                            {
                              validator: checkForWhiteSpace,
                            },
                          ]}
                          className="!w-full"
                          inputProps={{
                            placeholder: 'eg: John Doe',
                            className: '!w-full !border-transparent !shadow-none !border-b-grayScale rounded-none mb-0',
                          }}
                        />

                        <LabelInput
                          {...restField}
                          name={[name, 'email']}
                          rules={[
                            { required: true, message: 'Enter Email' },
                            { type: 'email', message: 'The input is not valid E-mail!' },
                          ]}
                          className="!w-full"
                          inputProps={{
                            placeholder: 'eg: john@gmail.com',
                            className: '!w-full !border-transparent !shadow-none !border-b-grayScale rounded-none mb-0',
                          }}
                        />

                        <LabelInput
                          {...restField}
                          name={[name, 'phone_number']}
                          inputType="number"
                          rules={[
                            { required: true, message: 'Phone number is required' },

                            {
                              pattern: phoneNumberRegex,
                              message: 'Please enter valid phone number',
                            },
                          ]}
                          className="!w-full"
                          inputNumberProps={{
                            placeholder: 'eg: +11234567890',
                            className: '!w-full !border-transparent !shadow-none !border-b-grayScale rounded-none mb-0',
                          }}
                        />

                        {index !== 0 && <CloseOutlined onClick={() => remove(name)} className="text-red-500" />}
                      </div>

                      {arr.length > 1 && index === 0 && (
                        <p className="text-secondaryDark text-base font-normal">More Collaborators</p>
                      )}
                    </div>
                  ))}
                  {fields.length <= 4 && (
                    <div className="flex justify-end">
                      <Form.Item className="mb-1">
                        <Button
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                          className="!border-none shadow-none text-primary"
                        >
                          Add Additional Cordinators
                        </Button>
                      </Form.Item>
                    </div>
                  )}
                </div>
              )}
            </Form.List>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-secondaryDark">You can add upto 4 Collaborators</p>
            <Button type="primary" onClick={handleNext} className="primaryBtn">
              Continue
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default Step5;
