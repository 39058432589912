/* eslint-disable global-require */
import React, { useState } from 'react';
import { Typography } from 'antd';
import { Button } from 'components/ui/Button/Button';
import { Spacer } from 'components/ui/Spacer';
import AddUsers from 'features/userManagement/components/AddUsers';
import { SvgIconName } from 'icons/enums';
import { RoutePaths } from 'routes/paths';
import { useCorporationDetails } from '../services/services';
import BestHabits from './BestHabits';
import Challenges from './Challenges';
import UserStats from './UserStats';

const Details = () => {
  const { corporationDetailsData } = useCorporationDetails();

  const numberOfUsers = corporationDetailsData?.no_of_users;

  const challengesCompleted = corporationDetailsData?.no_of_challenges_completed_this_week;

  const weekestHabit = corporationDetailsData?.areas_of_improvements;

  const strongestHabit = corporationDetailsData?.strongest_habits;

  const stats = corporationDetailsData?.challenges_stats;

  const communityName = corporationDetailsData?.details.name;

  const [addUserModal, setAddUserModal] = useState<boolean>(false);

  const closeAddUserModal = () => {
    setAddUserModal(false);
  };

  const requestAddUserHandler = () => {
    setAddUserModal(true);
  };
  const { Title } = Typography;
  return (
    <>
      <Title className="text-secondaryDarkest !text-2xl font-semibold">Dashboard</Title>
      <div className="flex justify-between items-center mb-2">
        <Title className="text-secondaryDarkest text-3xl !font-medium mb-0">User Stats</Title>
        <Button type="primary" className="primaryBtn h-11" onClick={requestAddUserHandler}>
          +Add User
        </Button>
        <AddUsers visible={addUserModal} onClose={closeAddUserModal} title={communityName} />
      </div>
      <div className="flex gap-6 justify-between">
        <UserStats
          svgIcon={SvgIconName.User}
          text="Number of Users in the app who have used the code"
          title={numberOfUsers || '0'}
          link="View Users"
          linkRoute={RoutePaths.UserManagement}
          img={require('assets/static/userStat-users.png')}
        />
        <UserStats
          svgIcon={SvgIconName.MedalStar}
          text="Number of challenges completed"
          title={challengesCompleted || 0}
          img={require('assets/static/userStat-challenges.png')}
          dropdown
        />
        <BestHabits strongestHabit={strongestHabit} weekestHabit={weekestHabit} />
      </div>

      <Spacer height="30px" />
      <div className="w-full">
        <Challenges stats={stats} />
      </div>
    </>
  );
};

export default Details;
