import { Dispatch, SetStateAction, useState } from 'react';
import { Form, DatePicker } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { Button } from 'components/ui/Button/Button';
import { Card } from 'components/ui/Card/Card';
import { FunctionWithParam } from 'utils/types';
import { useStepperContext } from '../context/StepperContext';

export interface CustomCalendarPros {
  onChange?: FunctionWithParam<string | null>;
  setDate: Dispatch<SetStateAction<Dayjs>>;
}
export const CustomCaledar = ({ onChange, setDate }: CustomCalendarPros) => {
  const handleButtonClick = (newValue: Dayjs) => {
    onChange?.(newValue.format('YYYY-MM-DD'));
    setDate(newValue);
  };

  return (
    <DatePicker
      open
      className="invisible"
      popupClassName="datePicker-popup"
      onSelect={handleButtonClick}
      defaultValue={dayjs(new Date())}
      disabledDate={(current) => current < dayjs().startOf('day')}
    />
  );
};

const Step6 = () => {
  const { handleNext, handlePrev } = useStepperContext();

  const [date, setDate] = useState<Dayjs>(dayjs(new Date()));

  return (
    <>
      <p className="stepFormTitle ">6. When would you like your New Life Challenge to begin?</p>
      <Card className="w-3/4 m-auto h-[522px]">
        <div className="flex flex-col h-full w-full">
          <div className="flex justify-start">
            <p onClick={handlePrev} className="cursor-pointer text-lg" aria-hidden>
              <span className="text-primary ">{`<-- `}</span>
              Back: Add Collaborators
            </p>
          </div>
          <div className="h-full w-full flex flex-col items-start ">
            <Form.Item
              name="start_date"
              rules={[{ required: true, message: 'Please pick a date!' }]}
              initialValue={date.format('YYYY-MM-DD')}
            >
              <CustomCaledar setDate={setDate} />
            </Form.Item>
          </div>
          {/* <div className="flex justify-end">
            <Button type="primary" onClick={handleNext} className="primaryBtn">
              Continue
            </Button>
          </div> */}
          <div className="flex justify-between items-center">
            <p className="text-secondaryDark">
              Date Selected: <span className="font-semibold">{date.format('D MMM YYYY')}</span>
            </p>
            <p className="text-secondaryDark">
              Todays Date: <span className="font-semibold">{dayjs(new Date()).format('D MMM YYYY')}</span>
            </p>
            <Button type="primary" onClick={handleNext} className="primaryBtn">
              Continue
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default Step6;
