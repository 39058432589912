import { Col, Row } from 'antd';
import { NewLifeCard } from 'components/ui/NewLifeCard';
import styles from './styles.module.scss';

export const NewLifesContainer = ({ data }: { data: any[] }) => {
  return (
    <div className={styles.newLifes}>
      <div className={styles.newLifes__wrap}>
        <Row gutter={[10, 10]}>
          {data.map((newLife: any) => (
            <Col md={8} sm={24} className={styles.newLifes__colWrapper} key={newLife.id}>
              <NewLifeCard
                image={newLife.image}
                title={newLife.title}
                desc={newLife.description}
                imageBgColor={newLife.imageBgColor}
                imageAlt={newLife.imageAlt}
                key={newLife.id}
              />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};
