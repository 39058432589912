import { NotifierTitle } from './enums';

const notFound = {
  title: 'ERROR 404!',
  subTitle: 'OPPS! PAGE NOT FOUND',
  detail: 'Sorry, the page you are looking for does not exist. If you think something is broken report a problem.',
  redirection: {
    url: '/',
    displayName: 'RETURN HOME',
  },
  problemUrl: {
    url: '/',
    displayName: 'REPORT PROBLEM',
  },
};

const errorMessages = {
  generic: {
    message: 'Something went wrong',
    description: 'Internal Server Error',
  },
  default: {
    message: 'Failed - ~~~',
    description: 'Could not ~~~ at the moment',
  },
};

const successMessages = {
  [NotifierTitle.GENERIC]: {
    message: 'Generic message',
    description: 'Generic description',
  },
  [NotifierTitle.LOGIN]: { message: 'Logged in Successfully.', description: 'You have been logged in.' },
  [NotifierTitle.SIGNUP]: { message: 'Signed up Successfully.', description: 'You have now signed up.' },
  [NotifierTitle.CONFIRMSIGNUP]: {
    message: 'Confirm Signed up Successfully.',
    description: 'You have now confirm signed up.',
  },
  [NotifierTitle.FORGOTPASSWORD]: {
    message: 'Forgot Password Successfully.',
    description: 'You have now confirm forgot password.',
  },
  [NotifierTitle.FORGOTPASSWORDSUBMIT]: {
    message: 'Forgot Password submit Successfully.',
    description: 'You have now confirm forgot password submit.',
  },
  [NotifierTitle.OAUTH]: {
    message: 'OAUTH login',
    description: 'OAuth Login Successful',
  },
  [NotifierTitle.STRIPE]: {
    message: 'Stripe',
    description: 'Success',
  },
  [NotifierTitle.CHANGEPASSWORD]: {
    message: 'Change Password',
    description: 'Changed Password Success',
  },
  [NotifierTitle.LIST_USERS]: {
    message: 'List Users',
    description: 'List Users Success',
  },
  [NotifierTitle.ADD_USERS]: {
    message: 'Add Users',
    description: 'Add Users Success',
  },
  [NotifierTitle.CORPORATION_DETAILS]: {
    message: 'Corporation Details',
    description: 'Get Corporation Details Success',
  },

  [NotifierTitle.USER_DETAILS]: {
    message: 'User Details',
    description: 'Get User Details Success',
  },
  [NotifierTitle.UPDATE_PROFILE]: {
    message: 'Update Profile',
    description: 'Profile Successfully Updated',
  },

  [NotifierTitle.UPDATE_PROFILE_PICTURE]: {
    message: 'Update Profile Picture',
    description: 'Profile Picture Successfully Updated',
  },

  [NotifierTitle.DELETE_USERS]: {
    message: 'Delete Users',
    description: 'Selected Users Successfully Deleted',
  },
  [NotifierTitle.GET_IN_TOUCH]: {
    message: 'Get in touch',
    description: 'Message Successfully Sent',
  },
};
export { errorMessages, notFound, successMessages };
