import React, { Key, useState } from 'react';
import { DeleteOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Table, Typography } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import clsx from 'clsx';
import { LabelInput } from 'components/ui/Form';
import { useDeleteUsers } from 'features/dashboard/services/services';
import { useListUsers } from '../services/services';
import AddUsers from './AddUsers';

interface DataType {
  pk: string;
  name: string;
  email: string;
  phone_number: string;
  community_code: string;
  status?: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'Contact Number',
    dataIndex: 'phone_number',
    render: (_, user) => <p>{user.phone_number || 'No Number'} </p>,
  },
  {
    title: 'Community Code',
    dataIndex: 'community_code',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    render: (_, user) => (
      <p className={clsx('bg-[#EDF6ED] text-center px-1 py-2 rounded-3xl text-[#20891B]')}>
        <span className="w-3 h-3 bg-[#20891B] inline-block rounded-full mr-2" />
        {user.status || 'Active'}{' '}
      </p>
    ),
  },
];

const UserList = () => {
  const { formSubmit, deleteUserMutateLoading } = useDeleteUsers();
  const { listUserData, listUserLoading } = useListUsers();
  const [addUserModal, setAddUserModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>([]);

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (_: Key[], selectedRows: DataType[]) => {
      const selectedId = selectedRows.map((user) => user.pk);
      setSelectedUser(selectedId);
    },
  };

  const onBtnClick = () => {
    formSubmit({ pks: selectedUser });
  };

  const closeAddUserModal = () => {
    setAddUserModal(false);
  };

  const requestAddUserHandler = () => {
    setAddUserModal(true);
  };
  const { Title } = Typography;

  return (
    <>
      <div className="flex justify-between items-center mb-10">
        <Title className="text-secondaryDarkest !text-2xl font-semibold">User Management</Title>
        <Button type="primary" className="primaryBtn h-11" onClick={requestAddUserHandler}>
          +Add User
        </Button>
        <AddUsers visible={addUserModal} onClose={closeAddUserModal} />
      </div>
      <div className="flex mb-8 justify-between items-center">
        <Button
          onClick={onBtnClick}
          loading={deleteUserMutateLoading}
          className="border-transparent bg-[#f5f5f5] px-3 py-2 rounded-full h-auto"
        >
          {`${selectedUser.length || 0} Selected | `} <DeleteOutlined className="!text-red-500 " /> Delete
        </Button>
        <LabelInput
          name="search"
          rules={[{ required: true, message: 'Please enter your community name' }]}
          className="w-80 mb-0"
          inputProps={{
            prefix: <SearchOutlined />,
            placeholder: 'Search for user name',
            className: '!rounded-3xl',
          }}
        />
      </div>

      <div>
        <Table
          rowSelection={{
            ...rowSelection,
          }}
          columns={columns}
          dataSource={listUserData}
          loading={listUserLoading}
          rowKey={(record) => record.pk}
        />
      </div>
    </>
  );
};

export default UserList;
