import { ThemeConfig } from 'antd/es/config-provider/context';

export const antdTheme: ThemeConfig = {
  token: {
    colorPrimary: '#5CC9D8',
    colorPrimaryActive: '#5CC9D8',
    colorLink: '#5CC9D8',
    colorLinkHover: '#5CC9D8',
    colorLinkActive: '#5CC9D8',
    fontFamily: 'Inter',
    fontSize: 16,
  },
  components: {
    Radio: {},
  },
};
