/* eslint-disable global-require */
import React, { FC } from 'react';
import { Button } from 'components/ui/Button/Button';
import Router from 'routes/Router';

const App: FC = () => {
  return (
    <>
      <div className=" w-screen h-screen block lg:hidden">
        <div className="bg-primaryDark h-full w-full items-center justify-center flex flex-col">
          <img src={require('assets/logo.png')} alt="The New Life" className="h-11" />

          <h1 className="text-white p-10 text-center">Live as the longest living communities and enjoy it!</h1>
          <div className="flex gap-4">
            <Button className="h-auto bg-transparent p-0 mt-4 border-none">
              <a
                href="https://play.google.com/store/apps/details?id=com.getthenewlife.tnl&hl=en&gl=US"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={require('assets/playStore.png')} alt="google play button" className="h-11" />
              </a>
            </Button>
            <Button className="h-auto bg-transparent p-0 mt-4 border-none">
              <a
                href="https://apps.apple.com/us/app/get-the-new-life/id1458514432"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={require('assets/appStore.png')} alt="google play button" className="h-11" />
              </a>
            </Button>
          </div>
        </div>
      </div>
      <div className="w-full hidden lg:block">
        <Router />
      </div>
    </>
  );
};

export default App;
