import { FC } from 'react';
import { Form, FormItemProps, SelectProps as AntSelectProps, Select } from 'antd';

const { Option } = Select;

type SelectInputProps = {
  selectProps?: AntSelectProps;
  options: { value: string | number; label: string }[];
};

export const SelectInput: FC<SelectInputProps & FormItemProps> = ({
  selectProps,
  options,
  ...props
}: SelectInputProps) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Form.Item {...props}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Select {...selectProps}>
        {options.map((item) => (
          <Option key={item.value} value={item.value}>
            {item.label}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};
