/* eslint-disable camelcase */
/* eslint-disable global-require */
import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';

interface AdminBoxProps {
  name?: string;
  email?: string;
  phone_number?: string;
  community_code?: string;
}

const AdminBox = ({ community_code, email, name, phone_number }: AdminBoxProps) => {
  return (
    <div className="border border-solid border-[#E0E0E0] rounded-lg p-8 flex flex-col w-[32%] relative gap-4">
      <div className="flex items-center gap-4 mb-4">
        <Avatar icon={<UserOutlined />} size={64} />
        <p className="font-semibold text-2xl">{name}</p>
      </div>

      <img
        src={require('assets/static/adminBox.png')}
        alt="description of upload"
        className=" absolute bottom-0 right-0"
      />
      <div className="flex items-center gap-4">
        <p className="font-semibold text-sm">Email :</p>

        <p className="font-normaltext-sm">{email}</p>
      </div>

      <div className="flex items-center gap-4">
        <p className="font-semibold text-sm">Contact Number :</p>

        <p className="font-normaltext-sm">{phone_number}</p>
      </div>

      <div className="flex items-center gap-4">
        <p className="font-semibold text-sm">Community Code :</p>

        <p className="font-normaltext-sm">{community_code}</p>
      </div>
    </div>
  );
};

export default AdminBox;
