import { createContext, Dispatch, ReactElement, SetStateAction, useContext, useMemo, useState } from 'react';

interface HeaderContextType {
  changeHeaderColor: boolean;
  setChangeHeaderColor: Dispatch<SetStateAction<boolean>>;
}

const HeaderContext = createContext({} as HeaderContextType);

interface Props {
  children: ReactElement;
}

const HeaderProvider = ({ children }: Props): ReactElement => {
  const [changeHeaderColor, setChangeHeaderColor] = useState<boolean>(false);

  const value = useMemo(
    () => ({
      changeHeaderColor,
      setChangeHeaderColor,
    }),
    [changeHeaderColor]
  );

  return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
};
export default HeaderProvider;

export const useHeader = (): HeaderContextType => useContext(HeaderContext);
