/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Divider } from 'antd';
import Slider, { Settings } from 'react-slick';
import { SvgIconName } from 'icons/enums';
import { SvgIcons } from 'icons/svgIcons';

const BestHabits = ({ strongestHabit = [], weekestHabit = [] }: any) => {
  const settings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
  };

  const getHabitDetails = ({ habitType, percentage }: any) => {
    switch (habitType) {
      case 'exercise':
        return (
          <>
            <SvgIcons iconName={SvgIconName.Exercise} width={20} height={20} fill="#E70969" />
            <p className="mt-3">{`${percentage.toFixed(2)}%`}</p>
            <p className="text-[#93003F] bg-[#FFE7F1] rounded-[32px] px-2 py-1">Exercise</p>
          </>
        );

      case 'education':
        return (
          <>
            <SvgIcons iconName={SvgIconName.Educate} width={20} height={20} fill="#5D5D5D" />
            <p className="mt-3">{`${percentage.toFixed(2)}%`}</p>
            <p className="text-[#474747] rounded-[32px] px-2 py-1 bg-[#F3F4F5]">Educate</p>
          </>
        );

      case 'enjoy_sunlight':
        return (
          <>
            <SvgIcons iconName={SvgIconName.Sunlight} width={20} height={20} fill="#DEAE0C" />
            <p className="mt-3">{`${percentage.toFixed(2)}%`}</p>
            <p className="text-[#927102] rounded-[32px] px-2 py-1 bg-[#FFF2C7]">Sunlight</p>
          </>
        );

      case 'invest_in_others':
        return (
          <>
            <SvgIcons iconName={SvgIconName.Invest} width={20} height={20} fill="#EB1515" />
            <p className="mt-3">{`${percentage.toFixed(2)}%`}</p>
            <p className="text-[#990000] rounded-[32px] px-2 py-1 bg-[#FFE9E9]">Invest</p>
          </>
        );

      case 'trust_more_and_stress_less':
        return (
          <>
            <SvgIcons iconName={SvgIconName.Trust} width={20} height={20} fill="#8C59E2" />
            <p className="mt-3">{`${percentage.toFixed(2)}%`}</p>
            <p className="text-[#370094] rounded-[32px] px-2 py-1 bg-[#EEE3FF]">Trust</p>
          </>
        );

      case 'live_temperately':
        return (
          <>
            <SvgIcons iconName={SvgIconName.Live} width={20} height={20} fill="#ED6B3A" />
            <p className="mt-3">{`${percentage.toFixed(2)}%`}</p>
            <p className="text-[#972900] rounded-[32px] px-2 py-1 bg-[#FFE2D8]">Live</p>
          </>
        );

      case 'have_refreshing_rest':
        return (
          <>
            <SvgIcons iconName={SvgIconName.Sleep} width={20} height={20} fill="#159DE5" />
            <p className="mt-3">{`${percentage.toFixed(2)}%`}</p>
            <p className="text-[#025480] rounded-[32px] px-2 py-1 bg-[#DFF4FF]">Rest</p>
          </>
        );

      case 'nutrition':
        return (
          <>
            <SvgIcons iconName={SvgIconName.Apple} width={20} height={20} fill="#20891B" />
            <p className="mt-3">{`${percentage.toFixed(2)}%`}</p>
            <p className="text-[#068400] rounded-[32px] px-2 py-1 bg-[#D2FFD0]">Nutrition</p>
          </>
        );

      case 'fresh_air':
        return (
          <>
            <SvgIcons iconName={SvgIconName.Apple} width={20} height={20} fill="red" />
            <p className="mt-3">{`${percentage.toFixed(2)}%`}</p>
            <p className="text-[red] rounded-[32px] px-2 py-1 bg-[#FFE7F1]">Air</p>
          </>
        );

      case 'water':
        return (
          <>
            <SvgIcons iconName={SvgIconName.Water} width={20} height={20} fill="#0E72ED" />
            <p className="mt-3">{`${percentage.toFixed(2)}%`}</p>
            <p className="text-[#013D88] rounded-[32px] px-2 py-1 bg-[#DAEAFF]">Water</p>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="border border-solid border-[#E0E0E0] rounded-lg p-8 flex flex-col w-[32%] justify-between">
      <div className="flex flex-col justify-between w-full h-full">
        <p className="font-normal text-xl leading-8 mb-3">{`${strongestHabit?.length} best habits`}</p>

        {strongestHabit?.length !== 0 ? (
          <Slider {...settings} className="habitSlider">
            {strongestHabit.map((habit: any, i: number) => (
              <div className="!flex flex-col gap-2 items-center" key={i.toString()}>
                {getHabitDetails({ habitType: habit.category, percentage: habit.completed_percentage })}
              </div>
            ))}
          </Slider>
        ) : (
          <p className="w-full h-full inline-flex justify-center items-center">No Best Habit</p>
        )}
      </div>
      <Divider className="m-3" />
      <div className=" flex flex-col justify-between w-full ">
        <p className="font-normal text-xl leading-8 mb-3">{`${weekestHabit?.length} weakest habits`}</p>
        {weekestHabit?.length !== 0 ? (
          <Slider {...settings} className="habitSlider">
            {weekestHabit.map((habit: any, i: number) => (
              <div className="!flex flex-col gap-2 items-center" key={i.toString()}>
                {getHabitDetails({ habitType: habit.category, percentage: habit.completed_percentage })}
              </div>
            ))}
          </Slider>
        ) : (
          <p className="w-full h-full inline-flex justify-center items-center">No Weakest Habit</p>
        )}
      </div>
    </div>
  );
};

export default BestHabits;
