/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
import { FC, useEffect } from 'react';
import AOS from 'aos';
import { Button } from 'components/ui/Button/Button';
import styles from './styles.module.scss';
import 'aos/dist/aos.css';

export const HeroContainer: FC = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className={styles.hero__image}>
      <div className={styles.hero__text__header}>
        <h3 className="text-2xl font-medium leading-[31px]" data-aos="fade-up" data-aos-duration="500">
          Pursuing Health Goals Together
        </h3>
        <div data-aos="fade-up" data-aos-duration="300">
          <h1 className={styles.hero__text__bottom}>{`Live as the longest\nliving communities\nand enjoy it!`}</h1>
        </div>
        <div className="flex gap-4">
          <Button className="h-auto bg-transparent p-0 mt-4 border-none">
            <div data-aos="fade-right" data-aos-duration="300" data-aos-delay="400">
              <a
                href="https://play.google.com/store/apps/details?id=com.getthenewlife.tnl&hl=en&gl=US"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={require('assets/playStore.png')} alt="google play button" className="h-11" />
              </a>
            </div>
          </Button>
          <Button className="h-auto bg-transparent p-0 mt-4 border-none">
            <div data-aos="fade-right" data-aos-duration="300" data-aos-delay="500">
              <a
                href="https://apps.apple.com/us/app/get-the-new-life/id1458514432"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={require('assets/appStore.png')} alt="google play button" className="h-11" />
              </a>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};
