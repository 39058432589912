/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable import/no-unresolved */
/* eslint-disable global-require */
import { FC, useEffect } from 'react';
import AOS from 'aos';
import { Button } from 'components/ui/Button/Button';
import { FAQs } from 'features/home/components/FAQs';
import { FewWords } from 'features/home/components/FewWords';
import { NewLifesContainer } from 'features/home/components/NewLifesContainer';
import ResourceAndInsights from 'features/home/components/ResourceAndInsights';
import { AboutData } from 'features/home/constant/NewLifeData';
import styles from './styles.module.scss';
import 'aos/dist/aos.css';

export const TopContent: FC = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <div className={styles.hero__image}>
        <div className="flex justify-center w-full">
          <h1 className="text-5xl text-white font-bold" data-aos="fade-up" data-aos-duration="300">
            About The New Life
          </h1>
        </div>
      </div>
      <div
        className="flex flex-col max-w-[840px] w-full mx-auto justify-center"
        data-aos="fade-up"
        data-aos-duration="300"
      >
        <h1 className="text-secondary font-semibold text-[40px] leading-[56px] mt-16 text-center">
          Our vision is to see <span className="text-primary text-center"> 1,000 communities </span> engage in
          supportive health journeys and seek to become their own little Blue Zones!
        </h1>
        <p className="text-lg font-normal leading-7 mt-5 text-center mb-12">{`The Blue Zones are communities around the world that have the lowest rates of every disease while also producing the most centenarians who are active well into their 90's.  (Blue Zone Map (it's in the app or I can send).  The only blue zone that isn't losing its longevity is found in Loma Linda, CA, within the community of Seventh-day Adventists.`}</p>
      </div>
      <div className="flex  max-w-[840px] w-full mx-auto justify-center" data-aos="fade-up" data-aos-duration="300">
        <img src={require('assets/map.png')} alt="World Map" className="max-w-[840px]  w-full" />
      </div>
      <div
        className="flex flex-col max-w-[840px] w-full mx-auto justify-center"
        data-aos="fade-up"
        data-aos-duration="300"
      >
        <h1 className="text-secondary font-medium text-4xl leading-[56px] mt-16 text-center">Our Principles</h1>
        <p className="text-lg font-normal leading-7 mt-5 text-center mb-12 w-[570px] mx-auto">
          The DNA that drives everything we do is made up of 10 lifestyle principles effectively observed in the longest
          living populations around the world, like in Loma Linda California, where the average resident lives at least
          10 years longer than their American counterpart.
        </p>
      </div>
      <div className="flex  max-w-[840px] w-full mx-auto justify-center" data-aos="fade-up" data-aos-duration="300">
        <img src={require('assets/principles.png')} alt="World Map" className="max-w-[840px] w-full" />
      </div>
      <div className="bg-[#cae6e8] mb-20 mt-20" data-aos="fade-up" data-aos-duration="300">
        <div className="flex   max-w-[840px] w-full mx-auto justify-center">
          {/* <img src={require('assets/video.png')} alt="World Map" className="max-w-[840px]   w-full" /> */}
          <video width="840" autoPlay muted loop>
            <source src="/assets/video/TNL.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
      <div className="flex mx-auto justify-center" data-aos="fade-up" data-aos-duration="300">
        <NewLifesContainer data={AboutData} />
      </div>
      <div
        className="bg-[#C4E4E2] mb-4 mt-20 w-[1010px] mx-auto rounded-3xl "
        data-aos="fade-up"
        data-aos-duration="300"
      >
        <div className="flex justify-center items-center gap-10 relative h-[400px]">
          <img src={require('assets/points.png')} alt="World Map" className="max-h-[500px] absolute left-10 bottom-0" />
          <h1 className="text-primaryDark font-bold leading-[56px] text-[40px] max-w-[40%] ml-40">
            We live to make your health journey into one of simplicity and pleasure, tailor-made for you and your life
          </h1>
        </div>
      </div>
      <div className="mb-0  w-[1010px] mx-auto rounded-3xl flex gap-4" data-aos="fade-up" data-aos-duration="300">
        <div className="bg-[#FEF3EF] w-1/2 p-8 rounded-3xl">
          <h3 className="font-semibold text-[32px]  leading-[48px]">
            The New Life is a scientifically-based health and wellness brand that provides customized health experiences
            for people to manage their own health through technology, community, and content.
          </h3>
          <p className="font-normal text-base leading-7 mt-8">
            Our vision is to see 1,000 communities engage in supportive health journeys and seek to become their own
            little Blue Zones.
          </p>
        </div>

        <div className="flex flex-col w-1/2 gap-4">
          <img src={require('assets/ready.png')} alt="World Map" className="max-w-[100%]" />

          <div className=" bg-[#F3F4F5] rounded-3xl flex flex-col justify-start px-[50px] py-[40px]">
            <p className="font-semibold text-2xl leading-[48px] text-secondaryDarkest">
              Download the app today and start!
            </p>
            <div className="flex gap-4  justify-start">
              <Button className="h-auto bg-transparent p-0 mt-4 border-none">
                <a
                  href="https://play.google.com/store/apps/details?id=com.getthenewlife.tnl&hl=en&gl=US"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require('assets/playStore.png')} alt="google play button" className="h-11" />
                </a>
              </Button>
              <Button className="h-auto bg-transparent p-0 mt-4 border-none">
                <a
                  href="https://apps.apple.com/us/app/get-the-new-life/id1458514432"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={require('assets/appStore.png')} alt="google play button" className="h-11" />
                </a>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <FAQs />
      <FewWords />
      <ResourceAndInsights />
    </>
  );
};
