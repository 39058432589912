import { FC, useState } from 'react';
import clsx from 'clsx';
import { Button } from 'components/ui/Button/Button';
import { FAQCard } from 'components/ui/Card';
import { Spacer } from 'components/ui/Spacer';
import { FAQType } from 'utils/enums';
import faqTopImage from '../../assets/faq/faq.png';
import { FaqTypes, faqsDatas } from './faq';

export const FAQsMain: FC = () => {
  const [selectedFaqType, setSelectedFaqType] = useState<FAQType>(FAQType.ALL);

  const handleFaqTypeClick = (faqType: FAQType) => {
    setSelectedFaqType(faqType);
  };

  const filteredFaqs =
    selectedFaqType === 'All' ? faqsDatas : faqsDatas.filter((faq) => faq.faqTypes.includes(selectedFaqType));

  return (
    <>
      <div>
        <div className="relative w-full">
          <img src={faqTopImage} alt="faq main" className="h-auto w-full" />
          <div className="max-w-screen-antd-xl m-auto absolute bottom-14 w-full left-1/2 -translate-x-1/2">
            <p className="bannerCaption">Frequently</p>
            <p className="bannerCaption mt-4">Asked Questions</p>
          </div>
        </div>
      </div>
      <Spacer height="40px" />

      <div className="max-w-screen-antd-xl mx-auto">
        <div className="flex gap-4">
          {FaqTypes.map((type) => (
            <Button
              className={clsx(
                'border-[#EDEEF0] text-secondary bg-[#EBF8FA] py-2 px-8 h-12',
                selectedFaqType === type && '!text-[#87dee6] !border-[#87dee6]'
              )}
              key={type}
              onClick={() => handleFaqTypeClick(type)}
            >
              {type}
            </Button>
          ))}
        </div>
        <Spacer height="60px" />
        {filteredFaqs.map((faqData) => {
          return (
            <>
              <FAQCard title={faqData.title} desc={faqData.desc} faqTypes={faqData.faqTypes} />
              <Spacer height="20px" />
            </>
          );
        })}

        <Spacer height="60px" />
      </div>

      <div className="bg-[#F5F5F5] p-20">
        <div className="max-w-screen-antd-xl mx-auto">
          <div className="text-center">
            <h3 className="font-medium text-[40px] leading-[64px] text-secondaryDarkest">Still need answers?</h3>
          </div>
          <Spacer height="40px" />
          <div className="flex gap-8">
            <div className="flex flex-col items-center gap-4">
              <h3 className="font-medium text-[22px] leading-8 text-secondaryDarkest">Read our blog</h3>
              <p className="text-center w-9/12 font-normal text-base leading-7 mb-2">
                Read helpful articles, life-hacks, new features and in-depth information on the new life
              </p>
              <a
                href="https://www.getthenewlife.com/blog/"
                target="_blank"
                rel="noreferrer"
                className="border-primary text-primary w-[204px] h-[56px] font-semibold text-base border-solid rounded-full flex justify-center items-center hover:border-[#5CC9D8] bg-white"
              >
                Read Blog
              </a>
            </div>
            <div className="flex flex-col items-center gap-4">
              <h3 className="font-medium text-[22px] leading-8 text-secondaryDarkest">Email us</h3>
              <p className="text-center w-9/12 font-normal text-base leading-7 mb-2">
                Our customer service team will be happy to help you and respond as soon as possible.
              </p>
              <a
                href="mailto:info@getthenewlife.com"
                target="_blank"
                rel="noreferrer"
                className="border-primary text-primary w-[204px] h-[56px] font-semibold text-base border-solid rounded-full flex justify-center items-center hover:border-[#5CC9D8] bg-white"
              >
                Email Us
              </a>
            </div>
            <div className="flex flex-col items-center gap-4">
              <h3 className="font-medium text-[22px] leading-8 text-secondaryDarkest">Follow Us</h3>
              <p className="text-center w-9/12 font-normal text-base leading-7 mb-2">
                Follow us on our Instagram page to get all the latest, exciting promotions and updates.
              </p>

              <a
                href="https://instagram.com/getthenewlife?igshid=MzRlODBiNWFlZA=="
                target="_blank"
                rel="noreferrer"
                className="border-primary text-primary w-[204px] h-[56px] font-semibold text-base border-solid rounded-full flex justify-center items-center hover:border-[#5CC9D8] bg-white"
              >
                Instagram
              </a>
            </div>
          </div>
          <Spacer height="40px" />
        </div>
      </div>
    </>
  );
};
