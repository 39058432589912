import { createElement, useEffect, useRef, useState } from 'react';
import { MenuUnfoldOutlined, MenuFoldOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import { Row, Col, Drawer, Anchor, Dropdown, Avatar } from 'antd';
import type { MenuProps } from 'antd';
import AOS from 'aos';
import clsx from 'clsx';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { ReactComponent as AuthHeaderLogo } from 'assets/authLogo.svg';
// import { ReactComponent as HeaderLogo } from 'assets/Logo.svg';
import { useAuth } from 'context/AuthContext';
import { useHeader } from 'context/HeaderContext';
import { useGetUserDetail } from 'features/dashboard/services/services';
import useClickOutside from 'hooks/useOutsideClick';
import useWindowDimensions from 'hooks/useWindowDimensions';
import { RoutePaths } from 'routes/paths';
import { AppButton } from '../Button/AppButton';
import { Button } from '../Button/Button';
import styles from './styles.module.scss';
import 'aos/dist/aos.css';

export const Header = ({ changeColor = false }: { changeColor?: boolean }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const { loggedIn, logout } = useAuth();
  const { changeHeaderColor } = useHeader();

  const { userDetailsData } = useGetUserDetail({ enabled: loggedIn });
  const [isListOpen, setListOpen] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  const windowDimensions = useWindowDimensions();
  const { width } = windowDimensions;
  const mobileView = width <= 1000;

  const wrapperRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  useClickOutside(wrapperRef, () => {
    if (isListOpen) {
      setListOpen(false);
    }
  });

  const toggleMenu = () => {
    setListOpen((prev) => !prev);
  };

  const onClose = () => {
    setVisible(false);
  };

  const onSignInClick = () => {
    navigate(RoutePaths.Login);
  };

  const onHomeClick = () => {
    navigate(RoutePaths.Dashboard);
  };

  const onSignUpClick = () => {
    navigate(RoutePaths.SignUp);
  };

  const onLogoutClick = () => {
    logout();
  };

  const onUserManagementClick = () => {
    navigate(RoutePaths.UserManagement);
  };

  const menu = [
    {
      id: 0,
      text: 'Home',
      function: onHomeClick,
    },
    {
      id: 1,
      text: 'About',
      function: () => {
        navigate(RoutePaths.About);
      },
    },
    {
      id: 2,
      text: 'FAQs',
      function: () => {
        //
      },
    },
    {
      id: 3,
      text: 'Blogs',
      function: () => {
        //
      },
    },
    {
      id: 4,
      text: 'Sign Up',
      function: onSignUpClick,
    },
    {
      id: 5,
      text: 'Sign In',
      function: onSignInClick,
    },
  ];

  const items: MenuProps['items'] = [
    {
      label: 'Settings',
      key: '1',
      icon: <UserOutlined />,
      onClick: () => navigate(RoutePaths.Settings),
    },

    {
      label: 'Admin Management',
      key: '2',
      icon: <UserOutlined />,
      onClick: () => navigate(RoutePaths.Collaborator),
    },

    {
      label: 'User Management',
      key: '3',
      icon: <UserOutlined />,
      onClick: onUserManagementClick,
    },

    {
      label: 'Log Out',
      key: '4',
      icon: <UserOutlined />,
      onClick: onLogoutClick,
    },
  ];

  const menuProps = {
    items,
  };

  return (
    <Row align="middle" justify="space-between" className={styles.header}>
      <Col span={24}>
        <Row justify="space-between">
          <div className={styles.logo}>
            <div data-aos="fade-down" data-aos-duration="300">
              <Link to="/">
                {changeColor || changeHeaderColor ? (
                  <AuthHeaderLogo />
                ) : (
                  // eslint-disable-next-line global-require
                  <img src={require('assets/logo.png')} alt="The New Life" className="h-10" />
                )}
              </Link>
            </div>
          </div>
          {!mobileView && (
            <div data-aos="fade-down" data-aos-duration="300">
              <Row align="middle" gutter={[40, 40]}>
                <Col>
                  <NavLink to="/">
                    <p
                      className={clsx(
                        changeColor || changeHeaderColor ? `${styles.nav} ${styles.authNav}` : styles.nav
                      )}
                    >
                      Home
                    </p>
                  </NavLink>
                </Col>
                {loggedIn && (
                  <Col>
                    <NavLink to={RoutePaths.Dashboard}>
                      <p
                        className={clsx(
                          changeColor || changeHeaderColor ? `${styles.nav} ${styles.authNav}` : styles.nav
                        )}
                      >
                        Dashboard
                      </p>
                    </NavLink>
                  </Col>
                )}
                <Col>
                  <NavLink to={RoutePaths.About}>
                    <p
                      className={clsx(
                        changeColor || changeHeaderColor ? `${styles.nav} ${styles.authNav}` : styles.nav
                      )}
                    >
                      About
                    </p>
                  </NavLink>
                </Col>
                <Col>
                  <NavLink to={RoutePaths.Faq}>
                    <p
                      className={clsx(
                        changeColor || changeHeaderColor ? `${styles.nav} ${styles.authNav}` : styles.nav
                      )}
                    >
                      FAQs
                    </p>
                  </NavLink>
                </Col>
                <Col>
                  <a href="https://www.getthenewlife.com/blog/" target="_blank" rel="noreferrer">
                    <p
                      className={clsx(
                        changeColor || changeHeaderColor ? `${styles.nav} ${styles.authNav}` : styles.nav
                      )}
                    >
                      Blogs
                    </p>
                  </a>
                </Col>
              </Row>
            </div>
          )}
          {mobileView ? (
            <div ref={wrapperRef}>
              <Button type="primary" onClick={toggleMenu}>
                {createElement(isListOpen ? MenuUnfoldOutlined : MenuFoldOutlined)}
              </Button>

              <Drawer placement="right" closable={false} onClose={onClose} open={visible}>
                <Anchor targetOffset={65} />
              </Drawer>
              {isListOpen && (
                <div className={styles.list}>
                  {menu.map((item) => {
                    return (
                      <div
                        className={styles.listButton}
                        onClick={item.function}
                        role="button"
                        onKeyDown={item.function}
                        tabIndex={0}
                        key={item.id.toString()}
                      >
                        <p className={styles.text}>{item.text}</p>
                      </div>
                    );
                  })}
                  <Dropdown menu={menuProps}>
                    <Button className="h-auto bg-primaryLight">
                      {userDetailsData?.profile_picture ? (
                        <Avatar size="large" className="mr-2" src={userDetailsData?.profile_picture} />
                      ) : (
                        <Avatar size="large" className="mr-2">
                          {userDetailsData?.profile_picture?.charAt(0)}
                        </Avatar>
                      )}
                      {userDetailsData?.name}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>
              )}
            </div>
          ) : (
            <Row align="middle" gutter={[16, 16]}>
              <div data-aos="fade-down" data-aos-duration="300">
                {loggedIn ? (
                  <Dropdown menu={menuProps}>
                    <Button className="h-auto bg-primaryLight">
                      {userDetailsData?.profile_picture ? (
                        <Avatar size="large" className="mr-2" src={userDetailsData?.profile_picture} />
                      ) : (
                        <Avatar size="large" className="mr-2">
                          {userDetailsData?.profile_picture?.charAt(0)}
                        </Avatar>
                      )}
                      {userDetailsData?.name}
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                ) : (
                  <Row gutter={[16, 16]} align="middle">
                    <Col>
                      <AppButton
                        onClick={onSignUpClick}
                        title="Sign Up"
                        btnStyle={clsx(styles.signUp__btn)}
                        textStyle={clsx(
                          styles.signUp__btn__text,
                          changeColor || changeHeaderColor ? `${styles.nav} ${styles.authNav}` : styles.nav
                        )}
                      />
                    </Col>
                    <Col>
                      <AppButton onClick={onSignInClick} title="Sign In" btnStyle={styles.signIn__btn} />
                    </Col>
                  </Row>
                )}
              </div>
            </Row>
          )}
        </Row>
      </Col>
    </Row>
  );
};
