import { FC } from 'react';
import { InputNumber as AntInputNumber, InputNumberProps as AntInputNumberProps } from 'antd';
import clsx from 'clsx';

const InputNumber: FC<AntInputNumberProps> = ({ className, ...props }) => {
  return (
    <AntInputNumber
      className={clsx('px-[16px] py-[12px] rounded-lg h-12', className)}
      type="number"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export { InputNumber };
