import React, { useState } from 'react';
import { Button, Form, Radio, RadioChangeEvent, Space } from 'antd';
import clsx from 'clsx';
import { Card } from 'components/ui/Card/Card';
import { useStepperContext } from '../context/StepperContext';

const Step1 = () => {
  const { handleNext } = useStepperContext();
  const [selectedButton, setSelectedButton] = useState(200);

  const handleRadioChange = (e: RadioChangeEvent) => {
    setSelectedButton(e.target.value);
  };

  return (
    <>
      <p className="stepFormTitle">{`1. Which plan fits your communities' size?`}</p>
      <Card className="w-3/4 m-auto h-[522px]">
        <div className="flex flex-col h-full w-full items-end">
          <div className="h-full w-full flex items-center justify-around">
            <Form.Item
              name="limit"
              rules={[{ required: true, message: 'Please select an option!' }]}
              className="flex-1"
              initialValue={200}
            >
              <Radio.Group
                className="flex justify-around items-center h-full"
                onChange={handleRadioChange}
                value={selectedButton}
              >
                <Space className="flex flex-col !gap-6 group/radio">
                  <div
                    className={clsx(
                      'h-[180px] w-[180px] transition-all ease-in-out duration-1000 rounded-full flex flex-col justify-center items-center group-hover/radio:bg-primaryLight group-hover/radio:border-primaryLight border-solid',
                      selectedButton === 200 ? 'bg-primaryLight !border-primaryDark ' : 'bg-sunLight border-sunLight'
                    )}
                  >
                    <p
                      className={clsx(
                        'text-[32px] font-semibold leading-[32px]',
                        selectedButton === 200 ? 'text-primaryDark' : 'text-secondaryDark'
                      )}
                    >
                      $12 /{' '}
                    </p>
                    <p
                      className={clsx(
                        'text-2xl  font-semibold leading-[32px]',
                        selectedButton === 200 ? 'text-primaryDark' : 'text-secondaryDark'
                      )}
                    >
                      person
                    </p>
                    <p className={clsx('text-base leading-[32px]', 'text-secondaryLight')}>Billed Annualy</p>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <p className="font-semibold text-lg text-secondaryDark">1 - 200 People</p>
                    <p className="text-lg text-secondaryLight">Other Information</p>
                    <p className="text-lg text-secondaryLight">Other Information</p>
                  </div>
                  <Radio.Button
                    className="!rounded-full !border-secondaryLight text-secondaryLight  hover:!border-primaryDark hover:text-primaryDark my-radio-button h-12 px-4 py-[9px]  text-base font-semibold inline-flex items-center justify-center w-52"
                    value={200}
                  >
                    {selectedButton === 200 ? 'Lets Build Community' : 'Select this plan'}
                  </Radio.Button>
                </Space>

                <Space className="flex flex-col !gap-6 group/radio">
                  <div
                    className={clsx(
                      'h-[180px] w-[180px] transition-all ease-in-out duration-1000 rounded-full flex flex-col justify-center items-center group-hover/radio:bg-primaryLight group-hover/radio:border-primaryLight border-solid',
                      selectedButton === 500 ? 'bg-primaryLight !border-primaryDark ' : 'bg-sunLight border-sunLight '
                    )}
                  >
                    <p
                      className={clsx(
                        'text-[32px] font-semibold leading-[32px]',
                        selectedButton === 500 ? 'text-primaryDark' : 'text-secondaryDark'
                      )}
                    >
                      $10 /{' '}
                    </p>
                    <p
                      className={clsx(
                        'text-2xl font-semibold leading-[32px]',
                        selectedButton === 500 ? 'text-primaryDark' : 'text-secondaryDark'
                      )}
                    >
                      person
                    </p>
                    <p className={clsx('text-base leading-[32px]', 'text-secondaryLight')}>Billed Annualy</p>
                  </div>

                  <div className="flex flex-col justify-center items-center">
                    <p className="font-semibold text-lg text-secondaryDark">201 - 500 People</p>
                    <p className="text-lg text-secondaryLight">Other Information</p>
                    <p className="text-lg text-secondaryLight">Other Information</p>
                  </div>
                  <Radio.Button
                    className="!rounded-full !border-secondaryLight text-secondaryLight  hover:!border-primaryDark hover:text-primaryDark my-radio-button h-12 px-4 py-[9px]  text-base font-semibold  inline-flex items-center justify-center w-52"
                    value={500}
                  >
                    {selectedButton === 500 ? 'Lets Build Community' : 'Select this plan'}
                  </Radio.Button>
                </Space>

                <Space className="flex flex-col !gap-6 group/radio">
                  <div
                    className={clsx(
                      ' relative h-[180px] w-[180px] transition-all ease-in-out duration-1000 rounded-full  flex flex-col justify-center items-center group-hover/radio:bg-primaryLight group-hover/radio:border-primaryLight border-solid',
                      selectedButton === -1 ? 'bg-primaryLight !border-primaryDark' : 'bg-sunLight border-sunLight'
                    )}
                  >
                    <p
                      className={clsx(
                        'text-[32px] font-semibold leading-[32px]',
                        selectedButton === -1 ? 'text-primaryDark' : 'text-secondaryDark'
                      )}
                    >
                      $8 /{' '}
                    </p>
                    <p
                      className={clsx(
                        'text-2xl font-semibold leading-[32px]',
                        selectedButton === -1 ? 'text-primaryDark' : 'text-secondaryDark'
                      )}
                    >
                      person
                    </p>
                    <p className={clsx('text-base leading-[32px]', 'text-secondaryLight')}>Billed Annualy</p>
                    <div className="absolute bg-[#ED6B3A] flex justify-center items-center flex-col w-[60px] h-[60px] rounded-full border-[3px] border-white border-solid top-0 -right-2">
                      <p className="text-white text-center text-xs font-semibold leading-[13px]">Save</p>
                      <p className="text-white text-center  text-xs font-semibold leading-[13px]">20%</p>
                    </div>
                  </div>

                  <div className="flex flex-col justify-center items-center">
                    <p className="font-semibold text-lg text-secondaryDark"> 500 + People</p>
                    <p className="text-lg text-secondaryLight">Other Information</p>

                    <p className="text-lg text-secondaryLight">Other Information</p>
                  </div>
                  <Radio.Button
                    className="!rounded-full !border-secondaryLight text-secondaryLight  hover:!border-primaryDark hover:text-primaryDark my-radio-button h-12 px-4 py-[9px] text-base font-semibold  inline-flex items-center justify-center w-52"
                    value={-1}
                  >
                    {selectedButton === -1 ? 'Lets Build Community' : 'Select this plan'}
                  </Radio.Button>
                </Space>
              </Radio.Group>
            </Form.Item>
          </div>
          <Button type="primary" onClick={handleNext} className="primaryBtn">
            Continue
          </Button>
        </div>
      </Card>
      <div className="flex w-3/4 m-auto mt-[26px] justify-between mb-6">
        <p className="#5D5D5D">
          Currency: <span className="text-secondaryDark">USD</span>
        </p>

        <p className="#5D5D5D">
          Do you have any confusion? <span className="text-primary font-semibold">{`Contact Us Instead ->`}</span>
        </p>
      </div>
    </>
  );
};

export default Step1;
