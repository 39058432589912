import React from 'react';
import { Form } from 'antd';
import { Button } from 'components/ui/Button/Button';
import { LabelInput } from 'components/ui/Form';
import { useChangePassword } from 'features/auth/signup/services/services';

const ChangePassword = () => {
  const { formSubmit, changePasswordLoading } = useChangePassword();

  const onFinish = (values: any) => {
    formSubmit(values);
  };
  return (
    <Form layout="vertical" autoComplete="off" onFinish={onFinish} className="mt-4 flex flex-col w-1/3">
      <LabelInput
        name="old_password"
        label="Current Password"
        inputType="password"
        rules={[{ required: true, message: 'Please input your old password!' }]}
        inputProps={{
          type: 'password',
          placeholder: 'Enter your Old Password',
        }}
      />
      <LabelInput
        name="new_password1"
        label="New Password"
        inputType="password"
        rules={[{ required: true, message: 'Please input your new password!' }]}
        inputProps={{
          type: 'password',
          placeholder: 'Enter your New Password',
        }}
      />

      <LabelInput
        name="new_password2"
        label="Confirm Password"
        dependencies={['new_password1']}
        hasFeedback
        inputType="password"
        rules={[
          { required: true, message: 'Please input your new password to confirm!' },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('new_password1') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          }),
        ]}
        inputProps={{
          type: 'password',
          placeholder: 'Enter Password to Confirm',
        }}
      />

      <div className="flex justify-start w-32">
        <Button
          type="primary"
          className="text-center w-[100%] primaryBtn"
          htmlType="submit"
          loading={changePasswordLoading}
        >
          Submit
        </Button>
      </div>
    </Form>
  );
};

export default ChangePassword;
