/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { Modal } from 'components/modal';
import { Button } from 'components/ui/Button/Button';
import { LabelInput } from 'components/ui/Form';
import { FunctionWithNoParam } from 'utils/types';
import { checkForWhiteSpace, fullNameRegex, phoneNumberRegex } from 'utils/utilFunctions';
import { useAddUsers } from '../services/services';

export interface AddUsersModalProps {
  visible?: boolean;
  onClose?: FunctionWithNoParam;
  type?: string;
  title?: string;
}

const AddUsers = ({ visible = false, onClose, type = 'users', title }: AddUsersModalProps) => {
  const { AddUserMutateLoading, formSubmit } = useAddUsers();
  const onSubmit = (values: any) => {
    formSubmit(values);
  };
  return (
    <Modal visible={visible} width={700} onCancel={onClose}>
      <>
        <p className="text-base mb-2 text-secondaryDarkest">{title || 'Add Users'}</p>
        <Form onFinish={onSubmit} className="min-h-[350px] flex flex-col justify-between">
          <Form.List name={type} initialValue={[undefined]}>
            {(fields, { add, remove }) => (
              <div className="flex flex-col w-full">
                {fields.map(({ key, name, ...restField }, index) => (
                  <div key={key}>
                    <div className="flex w-full gap-4">
                      <LabelInput
                        {...restField}
                        name={[name, 'name']}
                        rules={[
                          { required: true, message: 'Enter Full Name' },
                          {
                            pattern: fullNameRegex,
                            message: 'Please enter valid full name',
                          },
                          {
                            validator: checkForWhiteSpace,
                          },
                        ]}
                        className="!w-full"
                        inputProps={{
                          placeholder: 'eg: John Doe',
                          className: '!w-full !border-transparent !shadow-none !border-b-grayScale rounded-none mb-0',
                        }}
                      />

                      <LabelInput
                        {...restField}
                        name={[name, 'email']}
                        rules={[
                          { required: true, message: 'Enter Email' },
                          { type: 'email', message: 'The input is not valid E-mail!' },
                        ]}
                        className="!w-full"
                        inputProps={{
                          placeholder: 'eg: john@gmail.com',
                          className: '!w-full !border-transparent !shadow-none !border-b-grayScale rounded-none mb-0',
                        }}
                      />

                      <LabelInput
                        {...restField}
                        name={[name, 'phone_number']}
                        inputType="number"
                        rules={[
                          { required: true, message: 'Phone number is required' },
                          {
                            pattern: phoneNumberRegex,
                            message: 'Please enter valid phone number',
                          },
                        ]}
                        className="!w-full"
                        inputNumberProps={{
                          placeholder: 'eg: +11234567890',
                          className: '!w-full !border-transparent !shadow-none !border-b-grayScale rounded-none mb-0',
                        }}
                      />

                      {index !== 0 && <CloseOutlined onClick={() => remove(name)} className="text-red-500" />}
                    </div>
                  </div>
                ))}

                {fields.length <= 4 && (
                  <div className="flex justify-end">
                    <Form.Item className="mb-1">
                      <Button
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                        className="!border-none shadow-none text-primary"
                        loading={AddUserMutateLoading}
                      >
                        Add Additional Cordinators
                      </Button>
                    </Form.Item>
                  </div>
                )}
              </div>
            )}
          </Form.List>
          <div className="flex justify-between items-center">
            <p className="text-secondaryDark">You can add upto 4 Collaborators</p>
            <Button type="primary" className="text-center primaryBtn w-24" htmlType="submit">
              Submit
            </Button>
          </div>
        </Form>
      </>
    </Modal>
  );
};

export default AddUsers;
