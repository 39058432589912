import React, { ChangeEvent, DragEvent, forwardRef } from 'react';
import clsx from 'clsx';
import { FunctionWithParam } from 'utils/types';

export interface UploadActionProp {
  type: 'SET_IN_DROP_ZONE' | 'ADD_FILE_TO_LIST' | 'REMOVE_FILE_AND_IMAGE_URL' | 'ADD_IMAGE_URL';
  inDropZone?: boolean;
  files?: FileList;
  imageUrl?: string;
}

export interface UploadStateProp {
  inDropZone?: boolean;
  fileList?: FileList;
  imageUrl?: string | null;
}

export interface UploadDropZoneProps {
  data?: UploadStateProp;
  onImageChange: FunctionWithParam<UploadActionProp>;
  showCircle?: boolean;
  companyProfile?: boolean;
  id?: string;
}

/**
 * UploadDropZone:
 * Send id property when there is more than one upload on the same page.
 * Id needs to be a unique string
 */
export const UploadDropZone = forwardRef<HTMLInputElement, UploadDropZoneProps>(
  ({ data, onImageChange, showCircle = false, companyProfile = false, id = '1' }, ref) => {
    // onDragEnter sets inDropZone to true
    const handleDragEnter = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onImageChange({ type: 'SET_IN_DROP_ZONE', inDropZone: true });
    };

    // onDragLeave sets inDropZone to false
    const handleDragLeave = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      onImageChange({ type: 'SET_IN_DROP_ZONE', inDropZone: false });
    };

    // onDragOver sets inDropZone to true
    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      // set dropEffect to copy i.e copy of the source item
      e.dataTransfer.dropEffect = 'copy';
      onImageChange({ type: 'SET_IN_DROP_ZONE', inDropZone: true });
    };

    // onDrop sets inDropZone to false and adds files to fileList
    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      // get files from event on the dataTransfer object as an array
      if (!e?.dataTransfer?.files) return;
      // ensure a file or files are dropped
      const { files } = e.dataTransfer;
      if (files && files.length > 0) {
        // dispatch action to add droped file or files to fileList
        onImageChange({ type: 'ADD_FILE_TO_LIST', files });
        // reset inDropZone to false
        onImageChange({ type: 'SET_IN_DROP_ZONE', inDropZone: false });
      }
    };

    // handle file selection via input element
    const handleFileSelect = (e: ChangeEvent<HTMLInputElement>) => {
      // get files from event on the input element as an array
      const imageTarget: HTMLInputElement = e.target as HTMLInputElement;
      if (!imageTarget?.files) return;
      // ensure a file or files are selected
      const { files } = imageTarget;
      if (files && files.length > 0) {
        // dispatch action to add selected file or files to fileList
        onImageChange({ type: 'ADD_FILE_TO_LIST', files });
      }
    };

    const isFileSelected = data?.fileList && data?.fileList?.length > 0;

    return (
      <div
        className="flex flex-col justify-center items-center rounded"
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
      >
        <input
          ref={ref}
          id={`fileSelect${id}`}
          type="file"
          accept="image/*"
          multiple={false}
          className="border-0 h-1 overflow-hidden p-0 !hidden whitespace-nowrap w-1"
          onChange={handleFileSelect}
        />
        <label htmlFor={`fileSelect${id}`}>
          <div className="relative cursor-pointer">
            <div
              className={clsx(
                `relative w-48 h-[78px] border-[1px] border-[rgb(217 217 217)] bg-[#fafafa] 
              before:content-['Upload'] before:absolute before:h-full before:w-full before:items-center before:justify-center before:flex rounded-xl overflow-hidden`,
                isFileSelected && `before:content-[''] border-primary__highlight`,
                data?.imageUrl && `before:content-['']`,
                showCircle && '!w-24 !rounded-full !h-24',
                companyProfile && '!w-24'
              )}
            >
              {isFileSelected ? (
                <img
                  src={URL.createObjectURL((data?.fileList as FileList)?.[0])}
                  alt="upload"
                  className="object-contain absolute h-full"
                />
              ) : (
                data?.imageUrl && <img src={data?.imageUrl} alt="upload" className="object-contain absolute h-full" />
              )}
            </div>
          </div>
        </label>
      </div>
    );
  }
);
