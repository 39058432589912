import React from 'react';
import { Form } from 'antd';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/ui/Button/Button';
import { Card } from 'components/ui/Card/Card';
import { LabelInput } from 'components/ui/Form';
import { useLogin } from 'features/auth/signup/services/services';
import { LoginPayload } from 'features/auth/signup/types/SignUpType';
import { RoutePaths } from 'routes/paths';

const LoginForm = () => {
  const { formSubmit, loginLoading } = useLogin();
  const navigate = useNavigate();

  const handleSubmit = (values: LoginPayload) => {
    const deviceToken = Date.now().toString();
    formSubmit({ ...values, device_token: deviceToken });
  };
  return (
    <Form layout="vertical" onFinish={handleSubmit}>
      <div className="p-8 py-28">
        <Card className="w-[540px] m-auto !p-12 rounded-[24px]">
          <div className="flex flex-col w-full h-full justify-center gap-10">
            <div className="flex flex-col justify-center ">
              <h1 className="text-4xl font-medium mb-4">Login to your account</h1>

              <p className="text-[14px] font-normal">
                New to The New Life?{' '}
                <span
                  className="text-primary underline cursor-pointer"
                  onClick={() => navigate(RoutePaths.SignUp)}
                  aria-hidden
                >
                  Sign up
                </span>
              </p>
            </div>
            <div className=" w-full flex flex-col items-start justify-center">
              <LabelInput
                name="email"
                rules={[
                  { required: true, message: 'Please input your email address!' },
                  { type: 'email', message: 'The input is not valid E-mail!' },
                ]}
                className="!w-full"
                inputProps={{
                  type: 'email',
                  placeholder: 'Email Address',
                  className: '!w-full !border-transparent !shadow-none !border-b-grayScale rounded-none mb-0',
                }}
              />

              <LabelInput
                name="password"
                inputType="password"
                rules={[{ required: true, message: 'Please input your password!' }]}
                className="!w-full"
                inputProps={{
                  type: 'password',
                  placeholder: 'Password',
                  autoComplete: 'on',
                  className: '!w-full !border-transparent !shadow-none !border-b-grayScale rounded-none mb-0',
                }}
              />
            </div>
            <div className="flex justify-start flex-2">
              <Button type="primary" className="primaryBtn w-40" htmlType="submit" loading={loginLoading}>
                Login
              </Button>
            </div>
          </div>
        </Card>
      </div>
    </Form>
  );
};

export default LoginForm;
