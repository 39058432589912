import { FC } from 'react';
import clsx from 'clsx';
import { FunctionWithNoParam } from 'utils/types';
import styles from './styles.module.scss';

export interface OutLinedButtonProps {
  title: string;
  onClick: FunctionWithNoParam;
  disabled?: boolean;
  loading?: boolean;
  btnStyle?: string;
}

export const OutLinedButton: FC<OutLinedButtonProps> = ({
  title,
  onClick,
  disabled,
  loading,
  btnStyle,
}: OutLinedButtonProps) => {
  return (
    <div
      className={clsx(styles.outlined__button, btnStyle)}
      onClick={disabled || loading ? () => null : onClick}
      role="button"
      onKeyPress={disabled || loading ? () => null : onClick}
      tabIndex={0}
    >
      <p>{title}</p>
    </div>
  );
};
