import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import clsx from 'clsx';
import { Button } from 'components/ui/Button/Button';
import { Card } from 'components/ui/Card/Card';
import { FunctionWithParam } from 'utils/types';
import { useStepperContext } from '../context/StepperContext';
import { communityGoals } from '../data/communityGoals';

export interface TestPros {
  onChange?: FunctionWithParam<string[] | null>;
  value?: string[];
  setSelectedValue: React.Dispatch<React.SetStateAction<string[]>>;
}

export const Test = ({ value = [], onChange, setSelectedValue }: TestPros) => {
  useEffect(() => {
    setSelectedValue(value);
  }, [setSelectedValue]);

  const toggleStringInArray = (goal: string, arr: string[]) => {
    const index = arr.indexOf(goal);
    if (index !== -1) {
      arr.splice(index, 1);
    } else {
      arr.push(goal);
    }
    return [...arr];
  };

  const handleButtonClick = (goal: string) => {
    const updatedValues = toggleStringInArray(goal, value);

    onChange?.(updatedValues);
  };

  return (
    <div className="flex flex-wrap gap-2 w-4/5">
      {communityGoals.map((goal) => {
        return (
          <Button
            onClick={() => handleButtonClick(goal)}
            className={clsx(
              'px-4 py-3 !rounded-2xl h-auto w-auto text-secondary',
              value.includes(goal) && '!border-primary !bg-primaryLight',
              'bg-gray-50 border-gray-600/40'
            )}
            key={goal}
          >
            {goal}
          </Button>
        );
      })}
    </div>
  );
};

const Step3 = () => {
  const { handleNext, handlePrev } = useStepperContext();
  const [selectedValue, setSelectedValue] = useState<string[]>([]);

  return (
    <>
      <p className="stepFormTitle ">3. What are the health goals for your community?</p>
      <Card className="w-3/4 m-auto h-[522px]">
        <div className="flex flex-col h-full w-full">
          <div className="flex justify-start">
            <p onClick={handlePrev} className="cursor-pointer  text-lg" aria-hidden>
              <span className="text-primary ">{`<-- `}</span>
              Back: Change Plan
            </p>
          </div>
          <div className="h-full w-full flex flex-col  justify-center">
            <Form.Item
              name="community_goals"
              rules={[{ required: true, message: 'Please select your community goals' }]}
              shouldUpdate
            >
              <Test setSelectedValue={setSelectedValue} />
            </Form.Item>
          </div>
          <div className="flex justify-between items-center">
            <p className="text-secondaryDark">
              You have selected{' '}
              <span className="rounded-full bg-primary w-[20px] h-[20px] inline-flex items-center justify-center text-[#EDEEF0] ">
                {selectedValue.length}
              </span>{' '}
              community goals
            </p>
            <Button type="primary" onClick={handleNext} className="primaryBtn">
              Continue
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default Step3;
