/* eslint-disable global-require */
import React, { useEffect } from 'react';
import AOS from 'aos';
import { AppButton } from 'components/ui/Button/AppButton';
import BlogCard from 'components/ui/Card/BlogCard';
import resourceImage from '../../../assets/resourcemain.png';
import 'aos/dist/aos.css';

const ResourceAndInsights = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className="bg-primaryLight pb-[100px] pt-[60px]">
      <div className="w-full max-w-[1000px] my-0 mx-auto pt-[70px] pb-[30px]">
        <h1 className="text-secondaryDarkest font-bold text-5xl leading-[56px] text-center mb-14">
          Resource and Insights
        </h1>

        <div className="flex gap-6">
          <div className="flex-1">
            <div className="flex flex-col gap-6">
              <div className="relative" data-aos="flip-left" data-aos-duration="1000">
                <img src={resourceImage} alt="resource" className="max-w-[100%] max-h-[100%]" />
                <div className="absolute px-9 py-16  text-white top-0">
                  <h2 className="font-semibold text-2xl leading-[34px] mb-6">Disruptive Knowledge</h2>
                  <p className="text-base font-normal leading-[26px]  text-white mb-[38px]">
                    Temperance — In a world where ignorance is the true poverty.
                  </p>
                  <a href="https://www.getthenewlife.com/blog/" target="_blank" rel="noreferrer">
                    <AppButton title="Read all blogs" />
                  </a>
                </div>
              </div>
              <BlogCard
                image={require('assets/coffee.jpg')}
                imageAlt="Caffeine: Fool’s Gold"
                title="Caffeine: Fool’s Gold"
                link="https://www.getthenewlife.com/blog/caffeine-fools-gold/"
              />
            </div>
          </div>

          <div className="flex-1">
            <div className="flex flex-col  gap-6">
              <BlogCard
                image={require('assets/workSiteNutrition.jpg')}
                imageAlt="corporate"
                title="Worksite Nutrition"
                link="https://www.getthenewlife.com/blog/worksite-nutrition/"
                animate
              />
              <BlogCard
                image={require('assets/diet.jpg')}
                imageAlt="Physicians: Plant Based Diet Advocates"
                title="Physicians: Plant Based Diet Advocates"
                link="https://www.getthenewlife.com/blog/physicians-plant-based-diet-advocates/"
              />
              <BlogCard
                image={require('assets/blueZones.jpg')}
                imageAlt="Blue Zones – The World’s Longest Living People"
                title="Blue Zones – The World’s Longest Living People"
                link="https://www.getthenewlife.com/blog/blue-zones-the-worlds-longest-living-people/"
              />

              <BlogCard
                image={require('assets/sugar.jpg')}
                imageAlt="Sugar and Asthma"
                title="Sugar and Asthma"
                link="https://www.getthenewlife.com/blog/sugar-and-asthma/"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourceAndInsights;
