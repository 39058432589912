import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/ui/Button/Button';
import { Card } from 'components/ui/Card/Card';
import { RoutePaths } from 'routes/paths';

const Step8 = () => {
  const navigate = useNavigate();

  const onButtonClick = () => {
    navigate(RoutePaths.Login);
  };

  return (
    <Card className="w-3/4 m-auto h-[522px]">
      <div className="flex flex-col h-full w-full">
        <div className="h-full w-full flex flex-col items-center justify-center">
          <div className="flex justify-center items-center flex-col w-3/5 gap-10">
            <div className="flex flex-col items-center justify-center gap-2">
              <h1>Email Sent With Community Code</h1>
              <p className="text-center">
                {` The community code has been sent to your email. Please give us 24 hours. If you don't receive it within
              24 hours, let us know.Thank you!`}
              </p>
            </div>
            <div className="flex justify-center">
              <Button type="primary" className="primaryBtn" onClick={onButtonClick}>
                Continue
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default Step8;
