export const communityGoals = [
  'Weight Loss',
  'Reduce Blood Sugars',
  'Fight Heart Disease',
  'Improve Skin',
  'Lower Cholesterol',
  'Healthier Community',
  'Get Our Community Active',
  'Corporate Wellness',
  'Reduce Health Costs',
  'Reduce Medications',
  'Fitness',
  'Lower Blood Pressure',
  'Challenge Ourselves',
  'Improve Mental Health',
];
