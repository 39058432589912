import React, { useEffect, useState } from 'react';
import { useElements, useStripe, CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { Button } from 'antd';
import { Card } from 'components/ui/Card/Card';
import { LabelInput } from 'components/ui/Form';
import { Nullable } from 'utils/types';
import { useStepperContext } from '../context/StepperContext';

const Step7 = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<Nullable<string>>(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const { handlePrev, formRef, handleSubmit: handleFormSubmit, handleNext, signUpSuccess } = useStepperContext();

  const communityNumber = (formRef.getFieldValue('communityNumber') || 0) as number;
  const limit = formRef.getFieldValue('limit') as number;
  const communityName = formRef.getFieldValue('name') as string;

  useEffect(() => {
    if (signUpSuccess) {
      handleNext();
    }
  }, [handleNext, signUpSuccess]);

  const handlePayment = async (paymentMethodId: string) => {
    formRef.setFieldValue('payment_method', paymentMethodId);
    handleFormSubmit();
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setProcessing(true);
    const cardNumberElement = elements?.getElement(CardNumberElement);
    if (!cardNumberElement) {
      setError('Could not get card element.');
      setProcessing(false);
      return;
    }
    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: cardNumberElement,
      billing_details: {
        name: communityName,
      },
    });
    setProcessing(false);
    if (result.error) {
      setError(result.error.message as string);
      setSucceeded(false);
    } else {
      setError(null);
      setSucceeded(true);
      handlePayment(result.paymentMethod.id);
    }
  };

  const selectedPlan = (communityPlan: number) => {
    switch (communityPlan) {
      case 200:
        return {
          plan: '2 - 200 Peoples',
          rate: 12,
        };
      case 500:
        return {
          plan: '201 - 500 Peoples',
          rate: 10,
        };
      case -1:
        return {
          plan: '500+ Peoples',
          rate: 8,
        };
      default:
        return {
          plan: 'No Plan Selected',
          rate: 0,
        };
    }
  };

  return (
    <>
      <p className="stepFormTitle">6. Setup your payment</p>
      <Card className="w-3/4 m-auto h-[522px]">
        <div className="flex flex-col h-full w-full">
          <div className="flex justify-start">
            <p onClick={handlePrev} className="cursor-pointer text-lg" aria-hidden>
              <span className="text-primary ">{`<-- `}</span>
              Back: Program Start Date
            </p>
          </div>
          <div className="h-full w-full flex flex-col items-center justify-center gap-4">
            <div className="w-4/5 flex flex-col gap-8">
              <div className="w-full flex justify-between bg-primaryLight px-5 py-6 rounded-2xl items-center">
                <div className="flex flex-col">
                  <p className="text-gray-400 font-light text-base">
                    Selected Plan / <span className="text-gray-400/80 text-sm">{`${communityNumber} Peoples`}</span>
                  </p>
                  <p className="text-black/60 font-medium text-base">{selectedPlan(limit).plan}</p>
                </div>
                <p className="text-gray-400 font-light text-base">
                  <span className="flex items-center h-full gap-2">
                    Total Amount
                    <span className="text-black/60 font-medium text-2xl">{`$${
                      communityNumber * selectedPlan(limit).rate
                    }`}</span>
                  </span>
                </p>
              </div>
              <div className="w-full flex gap-8">
                <div className="w-full flex flex-col gap-1 ">
                  <span className="text-[#767676] font-light text-[14px] mb-1">Expiry Date</span>
                  <CardExpiryElement
                    className="w-ful stripeInput"
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#BDBDBD',
                          '::placeholder': { color: '#BDBDBD' },
                        },
                      },
                    }}
                    onChange={() => setError(null)}
                  />
                </div>
                <div className="w-full flex flex-col gap-1">
                  <span className="text-[#767676] font-light text-[14px] mb-1">CVC/CVV</span>
                  <CardCvcElement
                    className="w-full  stripeInput"
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#BDBDBD',
                          '::placeholder': { color: '#BDBDBD' },
                        },
                      },
                    }}
                    onChange={() => setError(null)}
                  />
                </div>
              </div>
              <div className="flex w-full flex-col">
                <div className="w-full flex flex-col gap-1">
                  <span className="text-[#767676] font-light text-[14px] mb-1">Card Number</span>
                  <CardNumberElement
                    className="w-full  stripeInput"
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          lineHeight: '24px',
                          color: '#BDBDBD',
                          '::placeholder': { color: '#BDBDBD' },
                        },
                      },
                    }}
                    onChange={() => setError(null)}
                  />
                </div>
              </div>
            </div>
            <div className="min-h-[22px]">{error && <div className="text-red-500 ">{error}</div>}</div>
            <div className="flex justify-center">
              <Button type="primary" onClick={handleSubmit} disabled={processing || succeeded} className="primaryBtn">
                {processing ? 'Processing...' : 'Confirmation & Continue'}
              </Button>
            </div>
            <p className="font-medium">Safe card payment via secure connection</p>
            <LabelInput
              name="payment_method"
              rules={[{ required: true, message: 'StripeKey' }]}
              className="!w-full hidden"
              inputProps={{
                placeholder: 'Stripe Key',
                className: '!w-full !border-transparent !shadow-none !border-b-grayScale rounded-none mb-0',
              }}
            />
          </div>
        </div>
      </Card>
    </>
  );
};

export default Step7;
