import React from 'react';
import { Form, Upload, UploadFile } from 'antd';
import { Button } from 'components/ui/Button/Button';
import { useUploadPhoto } from 'features/auth/signup/services/services';

const Protected = () => {
  const { formSubmit } = useUploadPhoto();

  const onFinish = (values: any) => {
    const file = values.profile_picture?.fileList?.[0] as unknown as UploadFile<FileList>;
    const originFile = file?.originFileObj as File;
    const formData = new FormData();
    formData.append('profile_picture', originFile, originFile.name);
    formSubmit(formData);
  };

  const { Dragger } = Upload;

  return (
    <div className="mt-20 bg-primaryLight">
      <div className="container p-20">
        <Form layout="vertical" autoComplete="off" onFinish={onFinish}>
          <Form.Item
            name="profile_picture"
            label="Upload Photo"
            rules={[{ required: true, message: 'Please upload your photo to continue' }]}
            className="mb-2 "
          >
            <Dragger
              className="border  rounded p-2 text-black__60 w-full"
              multiple={false}
              maxCount={1}
              beforeUpload={() => false}
            >
              <p className="ant-upload-text">Click or drag photo to upload</p>
            </Dragger>
          </Form.Item>

          <Button type="primary" className="text-center w-[100%] primaryBtn" htmlType="submit">
            Submit
          </Button>
        </Form>
      </div>
    </div>
  );
};

export { Protected };
