import axios, { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { AxiosConfig } from '../interfaces';

export const axiosWithConfig = async ({ withAuthentication = true }: AxiosConfig = {}): Promise<AxiosInstance> => {
  const customHeaders: AxiosRequestHeaders = {} as unknown as AxiosRequestHeaders;

  if (withAuthentication) {
    const token = localStorage.getItem('token');
    customHeaders.Authorization = `Token ${token}`;
  }

  return axios.create({
    timeout: 30000,
    headers: customHeaders,
  });
};
