/* eslint-disable global-require */
export const NewLifeData = [
  {
    id: 1,
    title: 'Simplicity',
    description:
      'Being healthy is not about juggling the latest health trends with your busy life. It is about clear goals reached by disruptive choices that do not disrupt your life.',
    image: require('assets/NewLifeCollab.png'),
    imageBgColor: 'rgba(140, 89, 226, 0.15)',
    imageAlt: 'collaboration icon',
  },
  {
    id: 2,
    title: 'Pleasure',
    description: `Ever wondered why kale doesn't taste like mint chocolate chip ice cream? Our program is designed to help you focus not just upon what to do, but also how to maximize enjoyment while easily making healthy decisions.`,
    image: require('assets/NewLifeHealthy.png'),
    imageBgColor: 'rgba(41, 163, 167, 0.15)',
    imageAlt: 'healthy life style icon',
  },
  {
    id: 3,
    title: 'Tailor-made',
    description: `When it comes to wholeness, there is no one-size fits all. Each person's life varies in schedule, access, and ability. As a result, we focus on principles that can be adapted into your everyday life, instead of around it.`,
    image: require('assets/NewLifeMotivated.png'),
    imageBgColor: 'rgba(231, 9, 105, 0.15)',
    imageAlt: 'get motivated everyday icon',
  },
];

export const AboutData = [
  {
    id: 1,
    title: 'Wholistic',
    description:
      '"Yes, the W is on purpose. We want everything we do to uplift a holistic way of living, where wholeness is restored in mind, body, and spirit."',
    image: require('assets/NewLifeCollab.png'),
    imageBgColor: 'rgba(140, 89, 226, 0.15)',
    imageAlt: 'collaboration icon',
  },
  {
    id: 2,
    title: 'Education',
    description: `"If Michelangelo had needed to say Ancora imparo - ‘I am still learning’ - at 87, we understand how important it is to be continually learning and challenging each other to be better versions of ourselves each day.`,
    image: require('assets/NewLifeHealthy.png'),
    imageBgColor: 'rgba(41, 163, 167, 0.15)',
    imageAlt: 'healthy life style icon',
  },
  {
    id: 3,
    title: 'Design',
    description: `"We believe health can be beautiful, on the inside and out. Design is that “out” we hope to inspire you to live your best."`,
    image: require('assets/NewLifeMotivated.png'),
    imageBgColor: 'rgba(231, 9, 105, 0.15)',
    imageAlt: 'get motivated everyday icon',
  },
];
