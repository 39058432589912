import { FC, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'antd';
import AOS from 'aos';
import { ReactComponent as DownArrow } from 'assets/arrows/DownArrow.svg';
import { ReactComponent as RightArrow } from 'assets/arrows/RightArrow.svg';
import { FAQType } from 'utils/enums';
import styles from './styles.module.scss';
import 'aos/dist/aos.css';

export interface FAQCardProps {
  title: string;
  desc: string;
  defaultOpen?: boolean;
  faqTypes: FAQType[];
}

export const FAQCard: FC<FAQCardProps> = ({ title, desc, defaultOpen = false, faqTypes }: FAQCardProps) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const [open, setOpen] = useState<boolean>(defaultOpen);
  const divRef = useRef<HTMLDivElement>(null);
  return (
    <div className={styles.faq} data-aos="zoom-in" data-aos-duration="300">
      <Row
        justify="space-between"
        align="middle"
        typeof="flex"
        onClick={() => {
          setOpen((prev) => !prev);
        }}
        className="cursor-pointer"
      >
        <Col span={23}>
          <h1>{title}</h1>
        </Col>
        <Col span={1}>
          <button type="button" className={styles.open__button}>
            {open ? <DownArrow /> : <RightArrow />}
          </button>
        </Col>
      </Row>
      <div
        className={styles.content__parent}
        ref={divRef}
        style={open ? { height: `${divRef.current?.scrollHeight}px` } : { height: '0px' }}
      >
        <p style={{ marginTop: '20px' }}>{desc}</p>
      </div>
      {faqTypes.map((faqType) => (
        <p key={faqType} className="hidden">
          {faqType}
        </p>
      ))}
    </div>
  );
};
