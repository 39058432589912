import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';

const AuthorizedRoutes = () => {
  const { loggedIn } = useAuth();

  return loggedIn ? <Outlet /> : <Navigate to="/" replace />;
};

export default AuthorizedRoutes;
