/* eslint-disable global-require */
import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Col, Row, Steps } from 'antd';
import AOS from 'aos';
import clsx from 'clsx';
import { Spacer } from 'components/ui/Spacer';
import { HeadingText } from './HeadingText';
import styles from './styles.module.scss';
import 'aos/dist/aos.css';

const Description = ({ value }: { value: number }) => {
  return (
    <>
      <p className={styles.trackCelebrate__timeline_title}>Daily Celebration</p>

      <div className={clsx(styles.trackCelebrate__timeline__descCon, value !== 0 && 'hidden')}>
        <p>Daily celebrations when reaching your daily goal.</p>
      </div>
    </>
  );
};

const Description1 = ({ value }: { value: number }) => {
  return (
    <>
      <p className={styles.trackCelebrate__timeline_title}>Weekly Report</p>

      <div className={clsx(styles.trackCelebrate__timeline__descCon, value !== 1 && 'hidden')}>
        <p>
          Each week get an update of your progress, see your trends, strongest habits, weakest habits, and set new goals
          for the next week to continue to grow in your health journey.
        </p>
      </div>
    </>
  );
};

const Description2 = ({ value }: { value: number }) => {
  return (
    <>
      <p className={styles.trackCelebrate__timeline_title}>End of Program Summary</p>

      <div className={clsx(styles.trackCelebrate__timeline__descCon, value !== 2 && 'hidden')}>
        <p>
          When completing a challenge, see a program in review to notice your strongest encouragers, and celebrate every
          healthy step you took along your health journey.
        </p>
      </div>
    </>
  );
};

const Description3 = ({ value }: { value: number }) => {
  return (
    <>
      <p className={styles.trackCelebrate__timeline_title}>Manage Profile</p>

      <div className={clsx(styles.trackCelebrate__timeline__descCon, value !== 3 && 'hidden')}>
        <p>
          Your profile allows you to see your strongest habits, weakest habits, who you predicted, if others predicted
          you and so much more!{' '}
        </p>
      </div>
    </>
  );
};

const StepComp = ({ current, setCurrent }: { current: number; setCurrent: Dispatch<SetStateAction<number>> }) => {
  const onChange = (value: number) => {
    setCurrent(value);
  };

  const stepData = [
    {
      title: 'Daily Celeberation',
      description: <Description value={current} />,
    },
    {
      title: 'Weekly Report',
      description: <Description1 value={current} />,
    },
    {
      title: 'End of Program Summary',
      description: <Description2 value={current} />,
    },
    {
      title: 'Manage Profile',
      description: <Description3 value={current} />,
    },
  ];

  const items = stepData.map((item) => ({ key: item.title, description: item.description }));

  return (
    <Steps
      current={current}
      onChange={onChange}
      progressDot
      direction="vertical"
      className={styles.track__steps}
      items={items}
    />
  );
};

export const TrackCelebrate: FC = () => {
  const [current, setCurrent] = useState<number>(0);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className={styles.trackCelebrate}>
      <div className={styles.trackCelebrate__wrap} data-aos="fade-up" data-aos-duration="1000">
        <HeadingText
          title="Manage profile"
          desc={`To live The New Life, we must celebrate every healthy decision and\n every ounce of progress we make. That's why The New Life  app has:`}
        />
        <Spacer height="50px" />
        <Row gutter={16}>
          <Col xs={24} md={10}>
            <Spacer height="50px" />
            <StepComp current={current} setCurrent={setCurrent} />
          </Col>
          <Col flex="auto" xs={24} md={14}>
            {current === 0 && (
              <img
                src={require('assets/manage_profile/celebration.png')}
                alt="description of upload"
                width="100%"
                className={styles.trackCelebrate__img}
              />
            )}
            {current === 1 && (
              <img
                src={require('assets/manage_profile/report.png')}
                alt="description of upload"
                width="100%"
                className={styles.trackCelebrate__img}
              />
            )}
            {current === 2 && (
              <img
                src={require('assets/manage_profile/summary.png')}
                alt="description of upload"
                width="100%"
                className={styles.trackCelebrate__img}
              />
            )}
            {current === 3 && (
              <img
                src={require('assets/manage_profile/profile.png')}
                alt="description of upload"
                width="100%"
                className={styles.trackCelebrate__img}
              />
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};
