import { useMutation } from '@tanstack/react-query';
import { postFetch } from 'lib/fetch';
import { NotifierTitle } from 'utils/enums';
import notifier from 'utils/Notifiers/Notifier';
import { GetInTouchApiPayload, GetInTouchPayload } from './types/GetInTouchType';

const useGetInTouch = () => {
  const useGetInTouchMutation = useMutation(
    (payload: GetInTouchApiPayload) => {
      return postFetch(`get-in-touch/`, payload);
    },
    {
      onSuccess: () => {
        // handle successful sign up
        notifier.success(NotifierTitle.GET_IN_TOUCH);
      },
      onError: (error) => {
        // handle sign up error
        const e = error as Error;
        return notifier.error(NotifierTitle.LOGIN, e.message);
      },
    }
  );

  const { mutate: getInTouchMutate, isLoading: getInTouchLoading } = useGetInTouchMutation;

  const formSubmit = (payload: GetInTouchPayload) => {
    const { email, fullName, message, formRef } = payload;
    getInTouchMutate(
      {
        full_name: fullName,
        message,
        sender_email: email,
      },
      {
        onSuccess: () => {
          formRef.resetFields();
        },
      }
    );
  };
  return { formSubmit, getInTouchMutate, getInTouchLoading };
};

export { useGetInTouch };
