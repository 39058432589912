import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getFetchAuth, postFetchAuth } from 'lib/fetch';
import { NotifierTitle } from 'utils/enums';
import notifier from 'utils/Notifiers/Notifier';

const useListUsers = () => {
  const useListUsersQuery = useQuery(
    ['listUsers'],
    () => {
      return getFetchAuth(`users/`);
    },
    {
      onSuccess: () => {
        // handle successful sign up
        // console.log(data);
        notifier.success(NotifierTitle.LIST_USERS);
      },
      onError: (error) => {
        // handle sign up error
        const e = error as Error;
        return notifier.error(NotifierTitle.LIST_USERS, e.message);
      },
    }
  );

  const { data: listUserData, isLoading: listUserLoading } = useListUsersQuery;
  return { listUserData, listUserLoading };
};

const useAddUsers = () => {
  const navigate = useNavigate();
  const useChangePasswordMutation = useMutation(
    (payload: any) => {
      return postFetchAuth(`users/`, payload);
    },
    {
      onSuccess: () => {
        // handle successful sign up
        // console.log(data);
        notifier.success(NotifierTitle.ADD_USERS);
        navigate(0);
      },
      onError: (error) => {
        // handle sign up error
        const e = error as Error;
        return notifier.error(NotifierTitle.ADD_USERS, e.message);
      },
    }
  );

  const { mutate: AddUserMutate, isLoading: AddUserMutateLoading } = useChangePasswordMutation;

  const formSubmit = (payload: any) => {
    AddUserMutate(payload);
  };
  return { formSubmit, AddUserMutateLoading };
};

const useListCordinators = () => {
  const useListCordinatorsQuery = useQuery(
    ['listCordinators'],
    () => {
      return getFetchAuth(`users/?coordinator=True`);
    },
    {
      onSuccess: () => {
        // handle successful sign up
        // console.log(data);
        notifier.success(NotifierTitle.LIST_USERS);
      },
      onError: (error) => {
        // handle sign up error
        const e = error as Error;
        return notifier.error(NotifierTitle.LIST_USERS, e.message);
      },
    }
  );

  const { data: listCordinatorsData, isLoading: listCordinatorsLoading } = useListCordinatorsQuery;
  return { listCordinatorsData, listCordinatorsLoading };
};

export { useListUsers, useAddUsers, useListCordinators };
