import { FC, useEffect } from 'react';
import { Row } from 'antd';
import AOS from 'aos';
import { useNavigate } from 'react-router-dom';
import { OutLinedButton } from 'components/ui/Button';
import { Spacer } from 'components/ui/Spacer';
import { RoutePaths } from 'routes/paths';
import { NewLifeData } from '../constant/NewLifeData';
import { NewLifesContainer } from './NewLifesContainer';
import styles from './styles.module.scss';
import 'aos/dist/aos.css';

export const WhyNewLife: FC = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  const navigate = useNavigate();

  const onKnowMoreClick = () => {
    navigate(RoutePaths.About);
  };
  return (
    <div className={styles.whyNewLife}>
      <div className={styles.whyNewLife__wrap}>
        <img
          // eslint-disable-next-line global-require
          src={require('assets/DividerImage.png')}
          alt="description of upload"
          className={styles.hero__bottom__image}
        />
        <Spacer height="100px" />

        <div className={styles.whyNewLife__text__header}>
          <h1 className={styles.whyNewLife__text__top} data-aos="fade-up" data-aos-duration="300">
            Why The New Life
          </h1>
          <p
            className={styles.whyNewLife__text__bottom}
            data-aos="fade-up"
            data-aos-duration="300"
          >{`We believe that health is a progressive journey together.  No one makes it on their own.\nMost health programs and challenges do not lead to sustainable health and wellness so\nthat we feel good and look good. Health is about hope and the whole person.  But it seems\nto thrive not in individuals but communities.`}</p>
        </div>
        <Spacer height="100px" />
        <div data-aos="fade-up" data-aos-duration="300" data-aos-delay="300">
          <NewLifesContainer data={NewLifeData} />
        </div>
        <Spacer height="100px" />
        <Row justify="center">
          <OutLinedButton title="Know more about new life" onClick={onKnowMoreClick} />
        </Row>
      </div>
    </div>
  );
};
