import { FC } from 'react';
import { Checkbox as AntCheckBox, CheckboxProps } from 'antd';
import clsx from 'clsx';

const CheckBox: FC<CheckboxProps> = ({ children, ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <AntCheckBox {...props} className={clsx(props.className)}>
      {/* TODO check below style for antd checkbox custom */}
      {/* <AntCheckBox {...props} className={clsx('custom-checkbox-style !bg-transparent', props.className)}> */}
      {children}
    </AntCheckBox>
  );
};

export { CheckBox };
