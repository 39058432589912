import { FC } from 'react';
import { Input as AntInput } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import clsx from 'clsx';

const { TextArea: AntTextArea } = AntInput;

const TextArea: FC<TextAreaProps> = ({ className, ...props }) => {
  return (
    <AntTextArea
      className={clsx('px-[16px] py-[12px] rounded-lg', className)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
};

export { TextArea };
