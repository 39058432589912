import { Login, SignUp, Protected } from 'pages';
import About from 'pages/about/About';
import ChangePassword from 'pages/ChangePassword';
import Collaborators from 'pages/collaborators/Collaborators';
import Dashboard from 'pages/dashboard/Dashboard';
import { Faq } from 'pages/faq/Faq';
import { Home } from 'pages/Home';
import Settings from 'pages/settings/Settings';
import UserManagement from 'pages/userManagement/UserManagement';
import { ConfigRoute, RouteType } from './interfaces';
import { RoutePaths } from './paths';

const { UNAUTHORIZED, ALLACCESS, AUTHORIZED } = RouteType;

const routes: ConfigRoute[] = [
  {
    feature: 'home',
    routeType: ALLACCESS,
    props: {
      path: RoutePaths.DefaultPage,
      component: Home,
      title: '',
    },
  },
  {
    feature: 'about',
    routeType: ALLACCESS,
    props: {
      path: RoutePaths.About,
      component: About,
      title: '',
    },
  },

  {
    feature: 'faq',
    routeType: ALLACCESS,
    props: {
      path: RoutePaths.Faq,
      component: Faq,
      title: '',
    },
  },
  {
    feature: 'login',
    routeType: UNAUTHORIZED,
    props: {
      path: RoutePaths.Login,
      component: Login,
      title: '',
    },
  },
  {
    feature: 'signUp',
    routeType: UNAUTHORIZED,
    props: {
      path: RoutePaths.SignUp,
      component: SignUp,
      title: '',
    },
  },

  {
    feature: 'protected',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.Protected,
      component: Protected,
      title: '',
    },
  },

  {
    feature: 'protected',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.ChangePassword,
      component: ChangePassword,
      title: '',
    },
  },

  {
    feature: 'protected',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.UserManagement,
      component: UserManagement,
      title: '',
    },
  },
  {
    feature: 'protected',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.Dashboard,
      component: Dashboard,
      title: '',
    },
  },
  {
    feature: 'protected',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.Settings,
      component: Settings,
      title: '',
    },
  },
  {
    feature: 'protected',
    routeType: AUTHORIZED,
    props: {
      path: RoutePaths.Collaborator,
      component: Collaborators,
      title: '',
    },
  },
];

export default routes;
