import { FC } from 'react';
import { Row, Typography } from 'antd';
import clsx from 'clsx';
import { Spacer } from '../Spacer';
import styles from './styles.module.scss';

const { Paragraph } = Typography;

export interface NewLifeCardProps {
  image: string;
  imageAlt?: string;
  title: string;
  desc: string;
  width?: number;
  height?: number;
  imageBgColor: string;
}

export const NewLifeCard: FC<NewLifeCardProps> = ({
  image,
  title,
  desc,
  width = 40,
  height = 40,
  imageBgColor,
  imageAlt,
}) => {
  return (
    <div className={clsx(styles.newLifeCard, styles.alignCenter)}>
      <Row align="middle" justify="center">
        <div className={clsx(styles.circleImgWrapper, styles.alignCenter)} style={{ backgroundColor: imageBgColor }}>
          <img
            src={image}
            width={width}
            height={height}
            className={styles.alignCenter}
            alt={imageAlt || 'new life icon'}
          />
        </div>
      </Row>
      <Spacer height="32px" />
      <h1 className={clsx(styles.headingText)}>{title}</h1>
      <Spacer height="28px" />
      <Paragraph className={clsx(styles.desc)}>{desc}</Paragraph>
    </div>
  );
};
