import { FC, ReactNode, useEffect } from 'react';
import { Col, Divider, Row } from 'antd';
import AOS from 'aos';
import { FunctionWithNoParam } from 'utils/types';
import { OutLinedButton } from '../Button';
import { AppButton } from '../Button/AppButton';
import { Spacer } from '../Spacer';
import styles from './styles.module.scss';
import 'aos/dist/aos.css';

export interface TextWrapProps {
  title: string;
  desc: ReactNode;
  icon: string;
  onRequestDemoClick: FunctionWithNoParam;
  onDownloadAppClick: FunctionWithNoParam;
}

export const TextWrap: FC<TextWrapProps> = ({ title, icon, desc, onRequestDemoClick, onDownloadAppClick }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className={styles.paragraphContainer} data-aos="fade-up" data-aos-duration="300">
      <div className={styles.wrapper}>
        <img src={icon} alt="how we do it icons" data-aos="fade-up" data-aos-duration="300" />
        <p className={styles.title}>{title}</p>

        <Divider type="vertical" className={styles.divider} />
        {desc}
        <Spacer height="40px" />
        <Row gutter={[16, 16]}>
          <Col>
            <AppButton onClick={onRequestDemoClick} title="Sign up today" btnStyle={styles.button} />
          </Col>
          <Col>
            <OutLinedButton onClick={onDownloadAppClick} title="Download the app" btnStyle={styles.button} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export interface ImgWrapProps {
  image: string;
  width?: number;
  height?: number;
}
export const ImgWrap: FC<ImgWrapProps> = ({ image, width = 470, height = 520 }) => {
  return (
    <Row justify="center">
      <div className={styles.divWrapper}>
        <div className={styles.imageWrapper}>
          <img
            className={styles.image}
            src={image}
            width={width}
            height={height}
            alt="challenge icon"
            data-aos="fade-up"
            data-aos-duration="300"
          />
        </div>
      </div>
    </Row>
  );
};

export interface PostProps {
  image: string;
  title: string;
  desc: ReactNode;
  icon: string;
  toggle?: boolean;
  onDownloadAppClick: FunctionWithNoParam;
  onRequestDemoClick: FunctionWithNoParam;
}

export const Post: FC<PostProps> = ({
  icon,
  desc,
  image,
  title,
  toggle,
  onRequestDemoClick,
  onDownloadAppClick,
}: PostProps) => {
  return (
    <Row gutter={[56, 30]} justify="center" align="middle" className={styles.post}>
      <Col md={12} xs={24} className="flex-center">
        {toggle ? (
          <ImgWrap image={image} />
        ) : (
          <TextWrap
            icon={icon}
            title={title}
            desc={desc}
            onDownloadAppClick={onDownloadAppClick}
            onRequestDemoClick={onRequestDemoClick}
          />
        )}
      </Col>
      <Col md={12} xs={24} className="flex-center">
        {toggle ? (
          <TextWrap
            icon={icon}
            title={title}
            desc={desc}
            onDownloadAppClick={onDownloadAppClick}
            onRequestDemoClick={onRequestDemoClick}
          />
        ) : (
          <ImgWrap image={image} />
        )}
      </Col>
    </Row>
  );
};
