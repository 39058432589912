import React, { FC } from 'react';
import { Button as AntButton, ButtonProps } from 'antd';
import clsx from 'clsx';

const Button: FC<ButtonProps> = ({ className, children, ...props }) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <AntButton className={clsx('rounded-full', className)} {...props}>
      {children}
    </AntButton>
  );
};

export { Button };
