import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { AppLayout } from 'layouts/AppLayout';
import { AuthLoyout } from 'layouts/AuthLoyout';
import { UnAuthLoyout } from 'layouts/UnAuthLoyout';
import ScrollToTop from 'ScrollToTop';
import AllAccessRoutes from './AllAccessRoutes';
import AuthorizedRoutes from './AuthorizedRoutes';
import { RouteType } from './interfaces';
import routes from './routeConfig';
import UnAuthorizedRoutes from './UnAuthorizedRoutes';

const Router = () => {
  const authorizedRoutes = routes.filter(({ routeType }) => {
    return routeType === RouteType.AUTHORIZED;
  });

  const unauthorizedRoutes = routes.filter(({ routeType }) => {
    return routeType === RouteType.UNAUTHORIZED;
  });

  const allaccessRoutes = routes.filter(({ routeType }) => {
    return routeType === RouteType.ALLACCESS;
  });

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route element={<AllAccessRoutes />}>
          {allaccessRoutes.map((route) => {
            const {
              props: { path, component: Component, ...rest },
            } = route;
            return (
              <Route
                key={path}
                path={path}
                element={
                  <AppLayout>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <Component {...rest} />
                  </AppLayout>
                }
              />
            );
          })}
        </Route>
        {/* UNAUTHORIZED ROUTES */}
        <Route element={<UnAuthorizedRoutes />}>
          <Route path="/auth" element={<Navigate to="/auth/login" />} />
          {unauthorizedRoutes.map((route) => {
            const {
              props: { path, component: Component, ...rest },
            } = route;
            return (
              <Route
                key={path}
                path={path}
                element={
                  <UnAuthLoyout>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <Component {...rest} />
                  </UnAuthLoyout>
                }
              />
            );
          })}
        </Route>
        {/* AUTHORIZED ROUTES */}
        <Route element={<AuthorizedRoutes />}>
          {authorizedRoutes.map((route) => {
            const {
              props: { path, component: Component, ...rest },
            } = route;
            return (
              <Route
                key={path}
                path={path}
                element={
                  <AuthLoyout>
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <Component {...rest} />
                  </AuthLoyout>
                }
              />
            );
          })}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
