import { axiosWithConfig } from './api';

const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`;

const getFetch = async (url: string, params = {}) => {
  const axios = await axiosWithConfig({ withAuthentication: false });

  const result = await axios({
    url: `${baseUrl}${url}`,
    method: 'GET',
    params,
  });
  return result.data;
};

const postFetch = async (url: string, data = {}) => {
  const axios = await axiosWithConfig({ withAuthentication: false });

  const result = await axios({
    url: `${baseUrl}/${url}`,
    method: 'POST',
    data,
  });
  return result.data;
};

const getFetchAuth = async (url: string, params = {}) => {
  const axios = await axiosWithConfig({ withAuthentication: true });

  const result = await axios({
    url: `${baseUrl}/${url}`,
    method: 'GET',
    params,
  });
  return result.data;
};

const postFetchAuth = async (url: string, data = {}) => {
  const axios = await axiosWithConfig({ withAuthentication: true });

  const result = await axios({
    url: `${baseUrl}/${url}`,
    method: 'POST',
    data,
  });
  return result.data;
};

const patchFetchAuth = async (url: string, data = {}) => {
  const axios = await axiosWithConfig({ withAuthentication: true });

  const result = await axios({
    url: `${baseUrl}/${url}`,
    method: 'PATCH',
    data,
  });
  return result.data;
};

const delFetchAuth = async (url: string, data = {}) => {
  const axios = await axiosWithConfig({ withAuthentication: true });

  const result = await axios({
    url: `${baseUrl}/${url}`,
    method: 'DELETE',
    data,
  });
  return result.data;
};

export { getFetch, postFetch, getFetchAuth, postFetchAuth, patchFetchAuth, delFetchAuth };
