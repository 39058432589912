import { createContext, ReactElement, useCallback, useContext, useMemo, useState } from 'react';
import { Form, FormInstance } from 'antd';
import { FunctionWithNoParam } from 'utils/types';
import { useSignUp } from '../services/services';
import { SignUpPayload } from '../types/SignUpType';

interface StepperContextType {
  handleNext: FunctionWithNoParam;
  handlePrev: FunctionWithNoParam;
  handleSubmit: FunctionWithNoParam;
  formRef: FormInstance<any>;
  currentStep: number;
  signUpSuccess: boolean;
}

interface StepperProviderProps {
  children: ReactElement;
}

export const StepperContext = createContext({} as StepperContextType);

const StepperProvider = ({ children }: StepperProviderProps): ReactElement => {
  const [formRef] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [formValues, setFormValues] = useState<SignUpPayload | null>(null);
  const { formSubmit, signUpSuccess } = useSignUp();
  const handleNext = useCallback(() => {
    formRef.validateFields().then((values) => {
      setFormValues({ ...formValues, ...values });
      setCurrentStep(currentStep + 1);
    });
  }, [currentStep, formRef, formValues]);

  const handlePrev = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const handleSubmit = useCallback(() => {
    formRef.validateFields().then((values) => {
      const finalValue = { ...formValues, ...values } as SignUpPayload;
      setFormValues({ ...formValues, ...values });
      formSubmit(finalValue);
    });
  }, [formRef, formSubmit, formValues]);

  const value = useMemo(
    () => ({
      handleNext,
      handlePrev,
      handleSubmit,
      formRef,
      currentStep,
      signUpSuccess,
    }),
    [handleNext, handlePrev, handleSubmit, formRef, currentStep, signUpSuccess]
  );

  return <StepperContext.Provider value={value}>{children}</StepperContext.Provider>;
};
export default StepperProvider;

export const useStepperContext = () => useContext(StepperContext);
