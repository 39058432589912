import React, { useEffect } from 'react';
import AOS from 'aos';

import 'aos/dist/aos.css';

interface BlogCardProps {
  image: string;
  imageAlt: string;
  title: string;
  link: string;
  animate?: boolean;
}

const BlogCard = ({ image, imageAlt, title, link, animate }: BlogCardProps) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <div className="flex rounded-3xl bg-white h-[197px]" data-aos={animate && 'flip-up'} data-aos-duration="1000">
      <div className="flex-1">
        <img
          src={image}
          alt={imageAlt}
          className="max-w-[100%] max-h-[100%] h-full w-full object-cover object-left-top"
        />
      </div>
      <div className="content flex-1 flex justify-center flex-col">
        <div className="px-8">
          <p className="font-semibold text-[22px] leading-[22px] text-secondary ">{title}</p>

          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className=" text-primary text-[18px] font-semibold leading-8 mt-2 block"
          >
            Learn more
          </a>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
