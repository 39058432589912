import React, { useEffect } from 'react';
import { useHeader } from 'context/HeaderContext';
import { FAQsMain } from 'features/faq/FAQsMain';

export const Faq = () => {
  const { setChangeHeaderColor } = useHeader();

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY >= 245) {
        setChangeHeaderColor(true);
      }
      if (window.scrollY <= 245) {
        setChangeHeaderColor(false);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setChangeHeaderColor]);
  return <FAQsMain />;
};
