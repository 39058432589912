import React from 'react';
import { LineChart, XAxis, Line, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { customData } from 'utils/utilFunctions';

const Charts = ({ data = [], statName, soryByType }: any) => {
  const userCustomData = customData({
    data,
    sortBy: soryByType,
  });

  return (
    <div>
      <ResponsiveContainer width="100%" height={500}>
        <LineChart
          width={500}
          height={300}
          data={userCustomData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false} strokeDasharray="5 5" />
          <Tooltip />
          <XAxis dataKey="date" strokeDasharray=" 5 5" />
          <Line type="monotone" dataKey="completed_users" stroke="#82ca9d" />
        </LineChart>
      </ResponsiveContainer>
      <div className="flex gap-2 mt-4">
        <div className="w-4 h-4 bg-[#82ca9d]" />
        <p>{statName.toUpperCase()}</p>
      </div>
    </div>
  );
};

export default Charts;
