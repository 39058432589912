import { FC, PropsWithChildren } from 'react';
import { Layout } from 'antd';
import { Footer } from 'components/ui/Footer';
import { Header } from 'components/ui/Header';
import styles from './styles.module.scss';

const { Header: AntdHeader, Content } = Layout;

export const AuthLoyout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Layout className={styles.userLayout}>
      <AntdHeader className={`${styles.header} ${styles.authHeader}`}>
        <Header changeColor />
      </AntdHeader>
      <Content className={styles.content}>
        <div className="mt-20 bg-white">
          <div className="max-w-screen-antd-xl m-auto py-10 px-8">{children}</div>
        </div>
      </Content>
      <Footer />
    </Layout>
  );
};
