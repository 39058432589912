import React, { FC, PropsWithChildren } from 'react';
import clsx from 'clsx';

export interface CardProps {
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
}

export const Card: FC<PropsWithChildren<CardProps>> = ({ children, className }) => {
  return (
    <div className={clsx('shadow-md bg-white rounded-lg border border-black__15 p-4 relative', className)}>
      {children}
    </div>
  );
};
