import { FC } from 'react';

export interface SpacerProps {
  height?: string;
  width?: string;
}

export const Spacer: FC<SpacerProps> = ({ height = '10px', width = '100%' }: SpacerProps) => {
  return <div className="spacer" style={{ height, width }} />;
};
