import { FC } from 'react';
import LoginForm from 'features/auth/login/components/LoginForm';

export const Login: FC = () => {
  return (
    <div className="mt-20 bg-primaryLight">
      <div className="container">
        <LoginForm />
      </div>
    </div>
  );
};
