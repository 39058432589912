/* eslint-disable global-require */
import { FC, useEffect } from 'react';
import AOS from 'aos';
import { useNavigate } from 'react-router-dom';
import { Post } from 'components/ui/Post';
import { Spacer } from 'components/ui/Spacer';
import { RoutePaths } from 'routes/paths';
import { HowWeDoItData } from '../constant/HowWeDoItData';
import { HeadingText } from './HeadingText';
import 'aos/dist/aos.css';
import styles from './styles.module.scss';

export const HowWeDoIt: FC = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  const navigate = useNavigate();
  return (
    <>
      <div className={styles.howWeDoIt}>
        <div className={styles.howWeDoIt__wrap} data-aos="fade-up" data-aos-duration="300" data-aos-delay="100">
          <HeadingText
            title="How we do it"
            desc={`We believe that health is a progressive journey together.\n  No one makes it on their own.`}
          />
          <Spacer height="100px" />
          {HowWeDoItData.map((post, index) => {
            const onDownloadAppClick = () => {
              if (index % 2 === 0) {
                window.open('https://apps.apple.com/us/app/get-the-new-life/id1458514432', '_blank');
              } else {
                window.open(
                  'https://play.google.com/store/apps/details?id=com.getthenewlife.tnl&hl=en&gl=US',
                  '_blank'
                );
              }
            };
            const onRequestDemoClick = () => {
              navigate(RoutePaths.SignUp);
            };
            return (
              <div key={post.id}>
                <Post
                  icon={post.icon}
                  desc={post.description}
                  image={post.image}
                  title={post.title}
                  toggle={index % 2 === 0}
                  onDownloadAppClick={onDownloadAppClick}
                  onRequestDemoClick={onRequestDemoClick}
                />
                <Spacer height="50px" />
              </div>
            );
          })}
        </div>
      </div>

      <div className="relative overflow-hidden h-[512px]">
        <img
          src={require('assets/mobAppBg.png')}
          alt="divider mobile"
          width="100%"
          height={512}
          style={{ objectFit: 'cover' }}
        />
        <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
          <img
            src={require('assets/mobGroup.png')}
            alt="divider mobile"
            width="120%"
            height="auto"
            data-aos="fade-up"
            data-aos-duration="1000"
            data-aos-delay="300"
            data-aos-offset="100"
          />
        </div>
      </div>
    </>
  );
};
