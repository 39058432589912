import { FC, PropsWithChildren } from 'react';
import { Layout } from 'antd';
import { Footer } from 'components/ui/Footer';
import { Header } from 'components/ui/Header';
import { useHeader } from 'context/HeaderContext';
import styles from './styles.module.scss';

const { Header: AntdHeader, Content } = Layout;

export const AppLayout: FC<PropsWithChildren> = ({ children }) => {
  const { changeHeaderColor } = useHeader();
  return (
    <Layout className={styles.userLayout}>
      <AntdHeader className={`${styles.header} ${changeHeaderColor && styles.authHeader}`}>
        <Header />
      </AntdHeader>
      <Content className={styles.content}>{children}</Content>
      <Footer />
    </Layout>
  );
};
