import React from 'react';
import { Spin } from 'antd';

const FullPageLoader = () => {
  return (
    <div className="w-full h-screen relative top-0 bottom-0 left-0 right-0 pb-56 flex items-center justify-center">
      <Spin />
    </div>
  );
};

export default FullPageLoader;
